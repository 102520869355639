import {isBrowser,isMobile} from 'react-device-detect';
//import config from "../config.json";

//const { usersEndpoint } = config;

export function getUserMenues() {
  //   return http.post(usersEndpoint + "/getUserTasks", {
  //     UserId: userId,
  //   });

  return {
    menues: [
      {
        id: "Home",
        Title: "Home",
        to: "/home",
        objectName: "Home",
        img: "Home.svg",
        activeImg: "HomeActive.svg",
        nonActiveImg: "Home.svg",
        beforeTitle: "",
        beforeTitleClass: "",
        beforeSpace: "1",
        beforeSpaceClass: "BeforeHomeSpace",

        regClassName: "SideMenuLink  nav-item",
        activeClassName: "SideMenuLink HomeMenuActive nav-item active",
        regTitleClassName: "sideMenuTitle",
        activeTitleClassName: "sideMenuTitle HomeMenuTitleActive",

        // activeStyle : ""
        // width: 0.375rem;
        // height: 3.25rem;
        // background-color: #1046ce;
      },
      {
        id: "LandRegister",
        Title: "Lands Register Printout",
        to: "/home/landRegister",
        objectName: "LandRegister",
        img: "LandRegister.svg",
        activeImg: "LandRegister.svg",
        nonActiveImg: "LandRegister.svg",

        beforeTitle: "",
        beforeTitleClass: "",
        beforeSpace: "1",
        beforeSpaceClass: "BeforeOnlineServicesSpace",

        regClassName: "SideMenuLink  nav-item",
        activeClassName: "SideMenuLink LandRegisterActive  nav-item active",

        regTitleClassName: "sideMenuTitle",
        activeTitleClassName: "sideMenuTitle LandRegisterTitleActive",
      },
      // {
      //   id: "Payments",
      //   Title: "Payments",
      //   to: "/home/payments",
      //   objectName: "Payments",
      //   img: "Payments.svg",
      //   activeImg: "Payments.svg",
      //   nonActiveImg: "Payments.svg",

      //   beforeTitle: "",
      //   beforeTitleClass: "",
      //   beforeSpace: "",
      //   beforeSpaceClass: "",
      //   regClassName: "SideMenuLink  nav-item",
      //   activeClassName: "SideMenuLink PaymentsActive  nav-item active",
      //   regTitleClassName: "sideMenuTitle",
      //   activeTitleClassName: "sideMenuTitle PaymentTitleActive",
      // },
      // {
      //   id: "AppFileTracking",
      //   Title: "Application File Tracking",
      //   to: "/home/applicationTracking",
      //   objectName: "ApplicationTraking",
      //   img: "FileTracking.svg",
      //   activeImg: "FileTracking.svg",
      //   nonActiveImg: "FileTracking.svg",
      //   beforeTitle: "",
      //   beforeTitleClass: "",
      //   beforeSpace: "",
      //   beforeSpaceClass: "",
      //   regClassName: "SideMenuLink  nav-item",
      //   activeClassName: "SideMenuLink AppFileTrackingActive  nav-item active",
      //   regTitleClassName: "sideMenuTitle",
      //   activeTitleClassName: "sideMenuTitle AppFileTrackingTitleActive",
      // },
      // {
      //   id: "PersonalZone",
      //   Title: "Personal Zone",
      //   to: "/home/myaccount",
      //   objectName: "MyAccount",
      //   img: "PersonalZone.svg",
      //   activeImg: "PersonalZone.svg",
      //   nonActiveImg: "PersonalZone.svg",
      //   beforeTitle: "",
      //   beforeTitleClass: "",
      //   beforeSpace: "",
      //   beforeSpaceClass: "",
      //   regClassName: "SideMenuLink  nav-item",
      //   activeClassName: "SideMenuLink PersonalZoneActive  nav-item active",
      //   regTitleClassName: "sideMenuTitle",
      //   activeTitleClassName: "sideMenuTitle PersonalZoneTitleActive",
      // },
      {
        id: "AboutUs",
        Title: "About Us",
        to: "/home/about",
        objectName: "AboutUs",
        img: "AboutUsNonActive.svg",
        activeImg: "AboutUsActive.svg",
        nonActiveImg: "AboutUsNonActive.svg",
        beforeTitle: "",
        beforeTitleClass: "",
        beforeSpace: "1",
        beforeSpaceClass: "BeforeHomeSpace",
        regClassName: "SideMenuLink  nav-item",
        activeClassName: "SideMenuLink AboutUsActive  nav-item active",
        regTitleClassName: "sideMenuTitle",
        activeTitleClassName: "sideMenuTitle AboutUsTitleActive",
      },
      {
        id: "ContactUs",
        Title: "Contact Us",
        to: "/home/contact",
        objectName: "Contact",
        img: "ContactUsNonActive.svg",
        activeImg: "ContactUsActive.svg",
        nonActiveImg: "ContactUsNonActive.svg",

        beforeTitle: "",
        beforeTitleClass: "",
        beforeSpace: "",
        beforeSpaceClass: "",
        regClassName: "SideMenuLink  nav-item",
        activeClassName: "SideMenuLink ContactUsActive  nav-item active",
        regTitleClassName: "sideMenuTitle",
        activeTitleClassName: "sideMenuTitle ContactUsTitleActive",
      },
    ],
  };
}

export function loadImage(imgName) {
  //const clientwindow = window.innerWidth;
  // console.log("LOAD IMAGE=", window.innerWidth);
  // console.log("LOAD IMAGE=", imgName);
  let imagesLib =
    window.innerWidth > 1366 && window.innerWidth <= 1920
      ? "resolution_1366"
      : window.innerWidth > 1280 && window.innerWidth <= 1366
      ? "resolution_1366"
      : "images";
  // console.log("LOAD IMAGE 2 =", imagesLib);
  let images = null;

  if (imagesLib === "resolution_1920") {
    images = require.context("../components/images/resolution_1920", true);
  } else if (imagesLib === "resolution_1366") {
    images = require.context("../components/images/resolution_1366", true);
  } else {
    images = require.context("../components/images", true);
  }

  let imgsrc = images(`./${imgName}`);
  if (imgsrc === null) {
    images = require.context("../components/images", true);
    //let imgsrc = images(`./${imgName}`);
  }
  return imgsrc;
}

const errorMessages = {
  PROPERTY_NOT_FOUND: {
    Title: "We are sorry",
    Message:
      "The property you are looking for does not exist in our records. Please review the property number or ",
    LinkTo: "/home/contact",
    LinkTitle: "contact us.",
    LinkClass: "",
    prevStage: "searchForm"
  },
  PAYMENT_FAILURE: {
    Title: "We are sorry",
    Message:
      "The payment didn't complete successfully. ",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    prevStage: "paymentDetails"
  },
  BILLING_DETAILS_MISSING: {
    Title: "",
    Message:
      "Please fill all the fields to move to the next stage. ",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    prevStage: "paymentDetails"
  },
  PaymentNotActivated: {
    Title: "We are sorry",
    Message:
      "The payment service is not available yet. ",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    prevStage: "paymentDetails"
  },
  DNandReNotCreated: {
    Title: "We are sorry",
    Message:
      "The creation of the Demand Notice and Receipt failed. Please try again. ",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    prevStage: "paymentDetails"
  },
  UserIDExistsWithOtherEmail: {
    Title: "",
    Message: "Your E-mail is not correct. Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
  },
  UserIDExistsWithOtherPhone: {
    Title: "",
    Message: "Your mobile number is not correct.  Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: true,
  },
  UserFullNameExistsWithOtherEmail: {
    Title: "",
    Message: "Your E-mail is not correct. Please try again.",
    LinkTo: "/home/register",
    LinkTitle: "Alternative, you may join us.",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserFullNameExistsWithOtherPhone: {
    Title: "",
    Message: "Your mobile is not correct. Please try again.",
    LinkTo: "/home/register",
    LinkTitle: "Alternative, you may  join us.",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserDoesNotExists: {
    Title: "",
    Message: "Not yet registered?",
    LinkTo: "/home/register",
    LinkTitle: "Join now.",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  InvalidEmailPassword: {
    Title: "",
    Message: "Invalid E-mail or Password",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserNameDoesNotExists: {
    Title: "",
    Message: "Sorry, we don't recognize that E-mail. Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserInvalidPassword: {
    Title: "",
    Message: "Incorrect password. Please try again or request to reset it.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  InternalOracleError: {
    Title: "",
    Message: "Sorry, there was an error in our system, please try again later.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  InternalError: {
    Title: "",
    Message: "Sorry, there was an error in our system, please try again later.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },

  WrongVerificationCode: {
    Title: "",
    Message: "Wrong Verification Code.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },

  CorrectNidWrongMobile: {
    Title: "",
    Message:
      "Sorry, we couldn't find an account with that mobile number.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongNidCorrectMobile: {
    Title: "",
    Message:
      "Sorry, we couldn't find an account with that National ID.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongNidAndWrongMobile: {
    Title: "",
    Message: "Sorry, you are not yet registered.",
    LinkTo: "/home/register",
    LinkTitle: "Join us!",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  CorrectNidWrongEmail: {
    Title: "",
    Message:
      "Sorry, we couldn't find an account with that E-mail.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongNidCorrectEmail: {
    Title: "",
    Message:
      "Sorry, we couldn't find an account with that National ID.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongNidAndWrongEmail: {
    Title: "",
    Message: "Sorry, you are not yet registered.",
    LinkTo: "/home/register",
    LinkTitle: "Join us!",
    LinkClass: "",
    IsLinkSeparate: false,
  },

  CorrectFullNameWrongMobile: {
    Title: "",
    Message:
      "Your mobile is not correct. Please try again.Alternative, you may ",
    LinkTo: "/home/register",
    LinkTitle: " join us.",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongFullNameCorrectMobile: {
    Title: "",
    Message:
      "Sorry, we couldn't find an account with that Name.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongFullNameAndWrongMobile: {
    Title: "",
    Message: "Sorry, we couldn't find an account.",
    LinkTo: "/home/register",
    LinkTitle: "Join us!",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  CorrectFullNameWrongEmail: {
    Title: "",
    Message:
      "Your mobile is not correct. Please try again.Alternative, you may ",
    LinkTo: "/home/register",
    LinkTitle: " join us.",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongFullNameCorrectEmail: {
    Title: "",
    Message:
      "Sorry, we couldn't find an account with that Name.Please try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  WrongFullNameAndWrongEmail: {
    Title: "",
    Message: "Sorry, we couldn't find an account.",
    LinkTo: "/home/register",
    LinkTitle: "Join us!",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserCreatedSuccessfully: {
    Title: "",
    Message: "User created successfully.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameNIDAlreadyExists: {
    Title: "",
    Message:
      "User with same ID number already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserAlreadyExists: {
    Title: "",
    Message: "User already registered. ",
    LinkTo: "/home/login/1",
    LinkTitle: "Log in",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameEmailAlreadyExists: {
    Title: "",
    Message:
      "User with same e-mail already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameNIDAndMobileAlreadyExists: {
    Title: "",
    Message:
      "User with same ID and mobile number already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameNIDAndEmailAlreadyExists: {
    Title: "",
    Message:
      "User with same ID and e-mail already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameNIDAndContactDetailsExists: {
    Title: "",
    Message:
      "User with same ID and contact details already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameContactDetailsExists: {
    Title: "",
    Message:
      "User with same contact details already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameMobileExists: {
    Title: "",
    Message:
      "User with same mobile number already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
  UserWithSameMobileAlreadyExists: {
    Title: "",
    Message:
      "User with same mobile number already exists.Please review the details and try again.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    IsLinkSeparate: false,
  },
};

export function getErrorMessages(keyword) {
  return errorMessages[keyword];
}

const titlesMessages = {
  LAND_REGISTER_GEO_PORTAL_TITLE: {
    Title: "Don’t have the property information?",
    Message: "$SET_LINK$ to find it on the Zambia Geographical Portal.",
    LinkTo: "/home/contact",
    LinkTitle: "Click here",
    LinkClass: "",
    TitleClass: "",
  },
  HELLO_TITLE: {
    Title: "",
    Message: "Hello",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },

  PERSONAL_ZONE_ZILMIS_USER_HELLO: {
    Title: "",
    Message: " you have been recognized",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },
  PERSONAL_ZONE_WEB_USER_HELLO: {
    Title: "",
    Message: " you have not been recognized",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },

  PERSONAL_ZONE_ZILMIS_USER_HELLO1: {
    Title: "",
    Message: " in our system! Please select a service to continue.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },
  PERSONAL_ZONE_WEB_USER_HELLO1: {
    Title: "",
    Message:
      " in our system as a property owner, If this is not the case, you can edit your personal data and we will do our best to find your records. In any case, you can continue to enjoy the online services provided by the portal: Lands Register Printouts, Payments and Application File Tracking.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },

  /*
  You have not been recognized as a property owner. 
  If this is not the case, 
  you can edit your personal data and we will do our best to find your records.
In any case, you can continue to enjoy the online services provided by the portal: Lands Register Printouts, Payments and Application File Tracking.
For more information click here or contact us.
  */

  PAYMENTS_SEARCH_DIDNT_MATCH_RECORDS: {
    Title: "We are sorry",
    Message:
      "Your search did not match our records. Please review the details or",
    LinkTo: "/home/contact",
    LinkTitle: " contact us. ",
    LinkClass: "",
    TitleClass: "",
  },
  NO_PROPERTIES_MESSAGE: {
    Title: "",
    Message: "You have not been recognized as a property owner.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },
  NO_LAND_REGISTER_PRINTOUT_MESSAGE: {
    Title: "",
    Message: "You have not purchased any Lands register printout.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },
  NO_OWNER_APPLICATIONS_MESSAGE: {
    Title: "",
    Message: "There is currently no open application for tracking.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },
  NO_APP_TRACKING_HISTORY_MESSAGE: {
    Title: "",
    Message: "There is currently no open application for tracking.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },

  NO_ONLINE_PAYMENTS_MESSAGE: {
    Title: "",
    Message:
      "You have not payed any Demand Notices with the Online Services application.",
    LinkTo: "",
    LinkTitle: "",
    LinkClass: "",
    TitleClass: "",
  },
};
/*
ZILMIS user
Hello Mila Gandel, you have been recognized in our system as a property owner. Please select a service to continue.
Web user
Hello Daniel Kure, you have not been recognized in our system as a property owner, if this is not the case, please contact us or select a service to continue.

*/
export function getTitleMessages(keyword) {
  return titlesMessages[keyword];
}

export function showProgressBar() {
  var progressbarImg = document.createElement("img");
  progressbarImg.id = "progressBarImage";
  progressbarImg.className = "ProgressBar";
  progressbarImg.src = loadImage("loading.gif");
  /*progressbarImg.style.position = 'absolute';
  progressbarImg.style.top = (window.innerHeight / 2).toString() + 'px';
  progressbarImg.style.left = (window.innerWidth / 2).toString() + 'px';*/
  if(isBrowser){
    progressbarImg.style.position = "fixed";
    progressbarImg.style.top = "50%";
    progressbarImg.style.left = "50%";
    progressbarImg.style.marginTop = "-50px";
    progressbarImg.style.marginLeft = "-50px";
  }
  if(isMobile){
    progressbarImg.style.position = "fixed";
    progressbarImg.style.top = "50%";
    progressbarImg.style.left = ((window.innerWidth / 2)- 24).toString() + "px";
  }
  progressbarImg.style.zIndex = "200";
  var root = document.getElementById("root");
  root.appendChild(progressbarImg);
}

export function hideProgressBar() {
  var progressbarImg = document.getElementById("progressBarImage");
  progressbarImg.parentElement.removeChild(progressbarImg);
}
