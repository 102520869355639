import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "../fields/input";
import Select from "../fields/select";
import smallSearchImage from './../images/smallSearch.svg';
//import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Form extends Component {
  state = { data: {}, errors: {}, errorMessage: ""};

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    //const { error} = Joi.validate(this.state.data, this.schema, options);

    const { error } = Joi.object(this.schema).validate(this.state.data, options);

    const { Land_No, Block_No, LGA, SurveyPlan_No, CofO_No} = this.state.data;

    if (!SurveyPlan_No && !CofO_No)
    {
      if ((!Land_No || !Block_No || !LGA)) {
        return {
          Land_No: 'The three fields (Plot, Block and LGA) are required',
          Block_No: 'The three fields (Plot, Block and LGA) are required',
          LGA: 'The three fields (Plot, Block and LGA) are required',
        };
      }
    }

    //const result = Joi.validate(this.state.data, this.schema, {
    //   abortEarly: false
    // });

    console.log("Joi", error);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  validateForm = () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    console.log("submited", errors);
    if (errors) {

      console.log('form.validateForm inside errors');

      var elements = document.getElementsByClassName("InputWrapperDiv");

      console.log('form.validateForm inside errors, elements.length: ' + elements.length);

      var mobileInputField = document.getElementById('mobileInput');
      if(mobileInputField !== undefined && mobileInputField!=null)
      {
        if(mobileInputField.className.indexOf('ErrorInputFieldPayment') > 0)
        {
          mobileInputField.className = mobileInputField.className.replace('ErrorInputFieldPayment','');
        }
      }

      var fieldWithErrorFound = false;
      for (var i = 0; i < elements.length; i++) {
        for (var key in errors) {

          console.log('elements[i].id: ' + elements[i].id + ", key: " + key);

          if(elements[i].id !== undefined && elements[i].id === "mobileInputDiv" && key === "PayerPhoneNumber")
          {
            //var mobileInputField = document.getElementById('mobileInput');
            mobileInputField.className = mobileInputField.className + " ErrorInputFieldPayment";

            this.errorMessage = errors[key];
  
            this.setState({ errorMessage: this.errorMessage });
            fieldWithErrorFound = true;
            break;
          }
          else{
            console.log(
              "elements[i].children[0].id: " +
                elements[i].children[0].id +
                " key:" +
                key +
                ",elements[i].children[0]: " +
                elements[i].children[0]["placeholder"]
            );
            if (elements[i].children[0].id === key) {
              this.errorMessage = errors[key];
  
              this.setState({ errorMessage: this.errorMessage });
              fieldWithErrorFound = true;
              break;
            }
          }
        }
        if (fieldWithErrorFound) {
          break;
        }
      }

      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
      return false;
    }
    else {
      return true;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // if (e.currentTarget.id !== null) {
    //   if (e.target.id.indexOf("_NotSubmitBtn") >= 0) {
    this.setState({ errors: {} });

    if (this.isSuccessUpdate !== undefined) {
      this.setState({ isSuccessUpdate: false });
    }

    if (this.successMessage !== undefined) {
      this.setState({ successMessage: "" });
    }

    var formValid = this.validateForm();

    console.log('form.handleSubmit : formValid:' + formValid);

    if(formValid)
    {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");

      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "hidden";
      }
      this.setState({ errors: {}, errorMessage: "" });
      /*var elements = document.getElementsByClassName('InputWrapperDiv');
      for(var i=0;i<elements.length;i++)
      {
        elements[i].children[1].style.display = 'none';
      }*/
      this.doSubmit();
    }
    else{
      return;
    }

    //   } else {
    //     return;
    //   }
    // } else {
    //   const errors = this.validate();
    //   this.setState({ errors: errors || {} });
    //   console.log("submited", errors);
    //   if (errors) return;

    //   this.doSubmit();
    // }
  };



  handleChange = ({ currentTarget: input }) => {
    //  const errors={};
    let errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    console.log("ERrMSG", errorMessage);
    /*if (errorMessage)
    {
      if((errorMessage.indexOf('([0-9]{3}') > 0))
      {
        errors[input.name] = 'Please enter mobile number (Correct format: XXX-X-XXXXXX)';
      }
      else{
        errors[input.name] = errorMessage;
      }
      if(input.parentElement.className.indexOf('tooltip') < 0)
      {
        input.parentElement.className = input.parentElement.className + ' tooltip';
      }
      input.parentElement.children[1].style.padding = '5px';
      input.parentElement.children[1].style.borderWidth = '1px';
    } 
    else
    {
      delete errors[input.name];
      input.parentElement.children[1].style.padding = '0px';
      input.parentElement.children[1].style.borderWidth = '0px';
    }*/

    const data = { ...this.state.data };
    data[input.name] = input.value;

    const fieldsToEmpty = ["Land_No", "Block_No", "LGA", "CofO_No", "SurveyPlan_No"];

    if (input.name === "Land_No" || input.name === "Block_No" || input.name === "LGA")
    {
      Object.keys(data).forEach(inputField => {
        if (inputField !== "Land_No" && inputField !== "Block_No" && inputField !== "LGA") {
          if (fieldsToEmpty.includes(inputField)) {
            data[inputField] = "";
          }
        }
      });
    } else{
      if (fieldsToEmpty.includes(input.name))
      {
        Object.keys(data).forEach(inputField => {
          
            if (inputField !== input.name && (fieldsToEmpty.includes(inputField))) {
              data[inputField] = "";
              this.emptySelectedValue()
            }
          
        });
      }
    }
    this.setState({
      data,
      errors
    });
    console.log("handleChange", data);
  };

  renderNotSubmitButton = (
    label,
    className = "btn btn-secondary",
    clickedFunc
  ) => {
    return (
      <button
        id={label + "_NotSubmitBtn"}
        className={className}
        onClick={clickedFunc}
        type="button"
      >
        {label}
      </button>
    );
  };
  renderButton = (label, className = "btn btn-secondary") => {
    return (
      <button
        id={label + "_SubmitBtn"}
        className={className}
        onClick={this.validateFields}
        type="submit"
      >
        {label}
      </button>
    );
  };

  // renderButton = (label, className = "btn btn-secondary") => {
  //   return (
  //     <button className={className} disabled={this.validate()}>
  //       {label}
  //     </button>
  //   );
  // };

  validateFields = () => {
    /*var elements = document.getElementsByClassName('InputWrapperDiv');
    for(var i=0;i<elements.length;i++)
    {
      console.log('input: ' + elements[i].children[0].value.length);
      
      if(elements[i].className.indexOf('tooltip') < 0)
      {
        elements[i].className = elements[i].className + ' tooltip';
      }
    }*/
  };

  emptyFunction = () => {
    /*do nothing*/
  }

  renderImageButton = (label, className = "btn btn-secondary") => {
    return <button className={className}>{label}</button>;
  };

  renderInput(
    fname,
    label,
    divclass = "form-group",
    type = "text",
    placeholder,
    descId = "",
    descTxt = "",
    focused = "",
    readonly = "",
    emptySelectedValue = this.emptyFunction
  ) {
    const { data } = this.state;

        return (
      <Input
        name={fname}
        label={label}
        value={data[fname]}
        onChange={this.handleChange}
        divClassName={divclass}
        type={type}
        placeholder={placeholder}
        descId={descId}
        descTxt={descTxt}
        focused={focused}
        error={this.state.errors[fname]}
        readonly={readonly}
        emptySelectedValue = {emptySelectedValue}
      />
    );
  }

  renderPasswordNumericInput(
    fname,
    label,
    divclass = "form-group",
    type = "password",
    placeholder,
    descId = "",
    descTxt = "",
    focused = "",
    readonly = "",
    pattern= "[0-9]*",
    inputMode = "numeric"
  ) {
    const { data } = this.state;
    return (
      <Input
        name={fname}
        label={label}
        value={data[fname]}
        onChange={this.handleChange}
        divClassName={divclass}
        type={type}
        placeholder={placeholder}
        descId={descId}
        descTxt={descTxt}
        focused={focused}
        error={this.state.errors[fname]}
        readonly={readonly}
        pattern = {pattern}
        inputMode = {inputMode}
      />
    );
  }

  renderReadOnlyInput(
    fname,
    label,
    divclass = "form-group",
    type = "text",
    placeholder,
    descId = "",
    descTxt = "",
    focused = "",
    readonly = ""
  ) {
    const { data } = this.state;
    return (
      <Input
        name={fname}
        label={label}
        value={data[fname]}
        onChange={this.handleChange}
        divClassName={divclass }
        type={type}
        placeholder={placeholder}
        descId={descId}
        descTxt={descTxt}
        focused={focused}
        error={this.state.errors[fname]}
        readonly="readonly"
      />
    );
  }

  renderSelect(
    fname,
    label,
    showoptions,
    divclass = "form-group",
    descId = "",
    descTxt = "",
    focused = ""
  ) {
    const { data } = this.state;
    return (
      <Select
        name={fname}
        label={label}
        value={data[fname]}
        showoptions={showoptions}
        onChange={this.handleChange}
        divClassName={divclass}
        descId={descId}
        descTxt={descTxt}
        focused={focused}
        error={this.state.errors[fname]}
      />
    );
  }

  renderSearchButton = (label, className = "btn-secondary") => {
    return (
      <button className="search-button">
        Search
        <img src={smallSearchImage}  className="search-icon"/>
      </button>
    );
  };
}

export default Form;
