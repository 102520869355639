import React, { Component } from "react";
import {isBrowser} from 'react-device-detect';
class ForgotEmailSendConfirm extends Component {
  render() {
    const fname = this.props.fname;
    const lname = this.props.lname;
    const uid = this.props.uid;
    const uemail = this.props.uemail;
    const umobile = this.props.umobile;
    const uNationalId = this.props.uNationalId;

   
    console.log(fname);
    console.log(lname);
    console.log(uid);
    console.log(uemail);
    console.log(umobile);
    console.log(uNationalId);
    return (
      <React.Fragment>
      { isBrowser ? (
        <div
          className="smallCloseBtnDiv1"
          onClick={() => {
            this.props.closeEmailComformation();
          }}
        >
          <button
            className="closeBtn"
            onClick={() => {
              this.props.closeEmailComformation();
            }}
          ></button>
        </div>
      ) : ("")
  }
        <div className="LogInTitle">Incoming!</div>
        <div className="LogInTitle2">We just sent you an e-mail</div>
        <div className="LogInTitle2">
          Please follow the instructions in the e-mail
          <br />
          to access your account.
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default ForgotEmailSendConfirm;
