import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
import Form from "./form";
import { login } from "../../services/authService";
import { activateActivityTracker } from "../../services/uiService";
// import { showProgressBar } from "../../services/uiService";
import {
  showProgressBar,
  hideProgressBar,
  getErrorMessages,
} from "../../services/uiService";

//import Input from "../fields/input";

import Joi from "joi-browser";

//import { login } from "../../services/userservices";

class StandardLoginForm extends Form {
  userName = React.createRef();
  password = React.createRef();

  state = {
    //LoginUserPwdModel
    data: { userName: "", password: "" },
    errors: {},
    userError: {},
  };

  // schema = {
  //   userName: Joi.string()
  //     .required()
  //     .email({ tlds: { allow: false } })
  //     .label("E-mail"),
  //   password: Joi.string().required().label("Password"),
  // };

  schema = {
    userName: Joi.string()
      .label("Email")
      .required()
      .email()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Please enter your E-mail address";
              break;
            case "any.required":
              err.message = "Please enter your E-mail address";
              break;
            case "string.email":
              err.message = "Please enter a valid E-mail address";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    password: Joi.string()
      .label("Password")
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter your password";
              break;
            case "any.empty":
              err.message = "Please enter your password";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  };

  componentDidMount() {
    this.showContentAfterAnimation();
  }
  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  doSubmit = async () => {
    try {
      console.log("LOGIN SUBMIT 1");
      showProgressBar();
      console.log("LOGIN SUBMIT 2");
      this.setState({ userError: "" });
      console.log("LOGIN SUBMIT 3");
      const { data } = this.state;
      console.log("LOGIN SUBMIT 4");
      const { data: token } = await login(data.userName, data.password);
      console.log("LOGIN SUBMIT 5");
      
      if (token["status"] === "OK") {
        console.log("Submited result", token);
        console.log("Submited result 1 ", token["token"]);
        console.log("Submited result 2", token["expiration"]);

        sessionStorage.setItem("token", token["token"]);
        sessionStorage.setItem("tokenExparation", token["expiration"]);

        var dateNow = new Date(Date.now());
        var dateExpire = new Date(token["expiration"]);
        console.log("dateExpire: " + dateExpire);
        var timeoutMinutes = Math.round(
          (dateExpire.getTime() - dateNow.getTime()) / 1000 / 60
        );

        console.log("Time left:" + timeoutMinutes + " minutes");

        sessionStorage.setItem("TimeoutInSeconds", timeoutMinutes * 60);

        console.log("Submited");

        window.location = "/";
      } else if (token["status"] === "USER_ERROR") {
        const userError = getErrorMessages(token["message"]);
        this.setState({ userError: userError });
        console.log("ERROR MSG=" + this.state.userError.Message + ",Internal Error=" + token["message"]);
        // console.log("ERROR LINK", this.state.userError.LinkTo);
      }
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("FROM BAD REQUEST=", errors);
      }
    }
    hideProgressBar();
  };

  render() {
    const { data, errorMessage } = this.state;
    const errors = { ...this.state };
    const userError = { ...this.state.userError };
    //console.log("RENDER DATA=", data);
    //console.log("RENDER errors=", errors);
    console.log("RENDER userError=", userError);
    if (userError !== undefined || errorMessage !== undefined) {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    }

    return (
      <React.Fragment>
        {
          isBrowser === true ?
          (
            <div
            className="smallCloseBtnDivOneTimeLogin"
            onClick={() => {
              document.getElementById("Cancel_NotSubmitBtn").click();
            }}
            >
            <button
              className="closeBtn"
              onClick={() => {
                document.getElementById("Cancel_NotSubmitBtn").click();
              }}
            />
            </div>
          ) : ("") 
        }
        <div className="LogInTitle">Log in to your account</div>
        <div id="LoginContent">
          <div className="LogInTitle2">
            You personal area for online services
          </div>
          <div className="OneTimeTokenTitle">With your password</div>
          <div className="LoginForm">
            <form onSubmit={this.handleSubmit} className="formStandardLogin">
              <div id="ErrorMessageDiv" className="ErrorMessageDivLogIn">
                {errorMessage !== undefined && errorMessage.length > 0 && (
                  <span>
                    <span className="ErrorMessageExclamationMark">!</span>
                    {errorMessage}
                  </span>
                )}
                {userError !== undefined &&
                  userError.Title !== undefined &&
                  userError.Title.length > 0 && <p>{userError.Title}</p>}
                {userError !== undefined &&
                  userError.Message !== undefined &&
                  userError.Message.length > 0 && (
                    <span>
                      <span className="ErrorMessageExclamationMark">!</span>
                      {userError.Message}
                    </span>
                  )}

                {userError !== undefined &&
                  userError.LinkTo !== undefined &&
                  !userError.IsLinkSeparate && (
                    <span className="LoginWithPasswordLinkSpan">
                      <NavLink
                        className="LoginWithPasswordLink"
                        to={userError.LinkTo}
                      >
                        <span>{userError.LinkTitle}</span>
                      </NavLink>
                    </span>
                  )}
                {userError.LinkTo && userError.IsLinkSeparate && (
                  <span className="LoginWithPasswordLinkSpan">
                    <NavLink
                      className="LoginWithPasswordLink"
                      to={userError.LinkTo}
                    >
                      <span>{userError.LinkTitle}</span>
                    </NavLink>
                  </span>
                )}
              </div>
              {/* <div className="d-block justify-content-center"> */}
              <div className="row">
                {/* <div className="col">
                  <label className="col">E-mail</label>
                </div> */}
                {this.renderInput(
                  "userName",
                  "",
                  "colTextbox",
                  "email",
                  "E-mail",
                  "",
                  "",
                  "true"
                )}
              </div>

              <div className="row">
                {/* <div className="col">
                  <label className="col">Password</label>
                </div> */}
                {this.renderInput(
                  "password",
                  "",
                  "colTextbox",
                  "password",
                  "Password",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                <div className="colTextbox">
                  <div className="FormButtonsDiv">
                    {this.renderNotSubmitButton(
                      "Cancel",
                      "btnCancel",
                      this.props.closeLoginForm
                    )}
                    {this.renderButton("Log in", "btnConfirm")}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col"></div> */}
                <div className="col">
                  <div id="forgotPwdDiv">
                    <NavLink to="/home/forgotpwd">
                      <span className="forgotPwdText">
                        Forgot your password?
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>

              {/* <div className="col text-black d-flex justify-content-center">
                  {this.renderButton("Sign in")}
                </div> */}
              {/* </div> */}
            </form>
          </div>
          <div className="row">
            <div className="col">
              <div className="FormButtonsDiv">
                <div className="LoginWithPasswordTitle RegisterTitleMargin">
                  Not yet registered?{" "}
                  <NavLink
                    className="LoginWithPasswordLink"
                    to="/home/register"
                  >
                    <span> Join now </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StandardLoginForm;
