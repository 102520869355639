import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,Route } from "react-router-dom";

import logger from "./services/logServise";

import "./index.css";
import App from "./App";
//import ServiceWorker from "./serviceWorker";
import * as serviceWorker from "./serviceWorker";

// import "bootstrap/dist/css/bootstrap.css";
// import "font-awesome/css/font-awesome.css";

//mport * as Sentry from "@sentry/browser";

// Sentry.init({
//   dsn: "https://be9c2bcb1bf441a5b4abf14d75b13325@sentry.io/5186936"
// });

logger.init();

ReactDOM.render(
  <BrowserRouter>
    <Route path="/" component={App}/>
  </BrowserRouter>,
  document.getElementById("root")
);
//serviceWorker.unregister();
//serviceWorker.register();
//registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
