import http from "./httpService";
import config from "../config.json";

const { generalEndPoint } = config;

export async function createDNandRERequest(params, paramsData) {
  var result = null;

  try {
    //console.log('createDNandRERequest: data.IDNumber: ' + data.IDNumber + ',obj.feeValue: ' + obj.feeValue + ',obj.propertyNumber: ' + obj.propertyNumber);
    
    // var paymentDetails = {
    //   IDNumber: data.IDNumber,
    //   FirstName:data.FirstName,
    //   LastName:data.LastName,
    //   SecurityNumber:data.SecurityNumber,
    //   LandId:data.LandId,
    //   CardNumber: data.CardNumber,
    //   CardExpiryDate: data.CardExpiryDate,
    //   CardType: data.CardType,
    //   feeValue: data.feeValue,
    //   PaymentMethod:data.paymentmethod,
    // };
    result = await http.post(generalEndPoint + "/processbilling", 
    {
      Params: params,
      ParamsData: paramsData
    });
  } catch (ex) {
    console.log("createDNandRERequest.error: " + ex);
  }

  return result;
}