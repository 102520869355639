import React, { Component } from "react";

import WithTooltip from "./withTooltip";
class ShortValueField extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.shortValue}
        {this.props.showTooltip && (
          <div className="TooltipWrapper">{this.props.fullValue}</div>
        )}
      </React.Fragment>
    );
  }
}

export default WithTooltip(ShortValueField);
