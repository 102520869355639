import http from "./httpService";
import config from "../config.json";

const { paymentEndpoint } = config;


export async function initiatePayment(data) {
  let result = null;
  try {
    console.log('initiatePayment: data.revenueCode: ' + data.revenueCode + ',data.amount: ' + data.feeValue*100 + ', data.custRef: ' + data.custRef + ', data.fullName: ' + data.fullName);

    var paymentDetails = {
      revenueCode: data.revenueCode,
      amount: data.feeValue*100,//data.amount*100,
      custRef: data.custRef,
      fullName: data.fullName
    };

    result = await http.post(paymentEndpoint + "/initiatePayment", paymentDetails);

  } catch (ex) {
    console.log("initiatePayment.error: " + ex);
  }

  return result;
}


export async function makePayment(data) {
  let result = null;
  try {
    console.log('makePayment: data.orderId: ' + data.orderId + ',data.amount: ' + data.feeValue*100 + ',data.merchantCode: ' + data.merchant_code);

    var paymentDetails = {
      orderId: data.orderId,
      amount: data.feeValue*100,
      merchantCode: data.merchant_code
    };
    result = await http.post(paymentEndpoint + "/makePayment", paymentDetails);

  } catch (ex) {
    console.log("makePayment.error: " + ex);
  }

  return result;
}


export async function sendPaymentRequest(data, obj) {
  var result = null;

  try {

    console.log('sendPaymentRequest: data.PayerPhoneNumber: ' + data.PayerPhoneNumber + ',obj.feeValue: ' + obj.feeValue + ',obj.propertyNumber: ' + obj.propertyNumber + ',obj.OwnerId: ' + obj.OwnerId + ',obj.WebUserId: ' + obj.WebUserId);

    var paymentDetails = {
      PayerPhoneNumber: data.PayerPhoneNumber,
      PaymentAmount: obj.feeValue,
      PaymentCurrency: "EUR",
      PropertyNumber: obj.propertyNumber,
      IsZilmisUser: obj.OwnerId ? (obj.OwnerId > 0 ? "true" : "false") : "false",
      WebUserId: obj.WebUserId > 0 ? obj.WebUserId : null,
      OwnerId: obj.OwnerId > 0 ? obj.OwnerId : null,
      PaymentMethod:"MOMO",
    };
    result = await http.post(paymentEndpoint + "/requesttopay", paymentDetails);

  } catch (ex) {
    console.log("sendDNPaymentRequest.error: " + ex);
  }

  return result;
}

export async function sendDNPaymentRequest(data, obj) {
  var result = null;

  console.log('sendDNPaymentRequest: data.PayerPhoneNumber: ' + data.PayerPhoneNumber + ',obj.feeValue: ' + obj.feeValue + ',obj.propertyName: ' + obj.propertyName + ',data.DemandNoticeNumber: ' + data.DemandNoticeNumber + ',obj.OwnerId: ' + obj.OwnerId + ',obj.WebUserId: ' + obj.WebUserId + ',data.IsZilmisUser: ' + data.IsZilmisUser);

  var paymentDetails = {
    PayerPhoneNumber: data.PayerPhoneNumber,
    PaymentAmount: obj.feeValue,
    PaymentCurrency: "EUR",
    PropertyNumber: obj.propertyName,
    DemandNoticeNumber: data.DemandNoticeNumber,
    IsZilmisUser: data.IsZilmisUser,
    WebUserId: obj.WebUserId > 0 ? obj.WebUserId : null,
    OwnerId: obj.OwnerId > 0 ? obj.OwnerId : null,
    PaymentMethod:"MOMO"
  };
  try {
    result = await http.post(
      paymentEndpoint + "/requesttopaydemandnotice",
      paymentDetails
    );

  } catch (ex) {
    console.log("sendDNPaymentRequest.error: " + ex);
  }
  return result;
}
