import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useLocation,
  useCallback,
} from "react";
import { getSessionUser } from "../services/sessionServices";

import { Route, useRouteMatch, Link } from "react-router-dom";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
import UserContext from "../context/userContext";
import SideMenuContext from "../context/sideMenuContext";

import SideBar from "./fields/sidebar";
import MyAccount from "./myaccount";
import LandRegister from "./landRegister";
import Payments from "./payments";
import Login from "./login";
import Register from "./register";
import ApplicationTraking from "./applicationTraking";
import Contact from "./contact";
import AboutUs from "./about";
import WellcomeComponent from "./fields/wellcomeComponent";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";
import ServicesHelp from "./servicesHelp";
import OneTimeLoginForm from './forms/onetimeLoginform';
import StandardLoginForm from './forms/standardLoginForm';
import sideMobileMenu from './sideMobileMenu';





const Home = ({ location, match, history }) => {
  const context = useContext(UserContext);
  const [showWellcome, setWellcome ] = useState(true);
  const sideMenuContext = useContext(SideMenuContext);
  const functionsSet = new Set();
  
 

  const showFirstMessage = useCallback(() => {
    setWellcome(true);
    console.log("showFirstMessage");
  }, [showWellcome]);

  const hideFirstMessage = useCallback(
    (item) => {
      setWellcome(false);
     
      console.log("hideFirstMessage");
     
    },
    [showWellcome], 
   
  );
  
  const ShowMyAccount = useCallback(() => {
    setWellcome(false);
    console.log("hideFirstMessage");
    history.push("/home/myaccount");
  }, [showWellcome]);

  functionsSet.add(showFirstMessage);
  functionsSet.add(hideFirstMessage);
  functionsSet.add(ShowMyAccount);
  
  //functionsSet.add(collapseMobileMenu);

  let menues = sideMenuContext.menues;

  const componentsMap = {
    LandRegister,
    Payments,
    ApplicationTraking,
    MyAccount,
    AboutUs,
    Contact,
    SideBar,
    sideMobileMenu,
    Login,OneTimeLoginForm,StandardLoginForm
  };

  useEffect(() => {
    function getMenues() {
      if (!menues || menues.length === 0) {
        sideMenuContext.onHandleSideMenu();
        menues = sideMenuContext.menues;
        console.log("in new menues");
      }
    }

    getMenues();

    if (location.pathname !== "/home" && location.pathname !== "/") {
      hideFirstMessage();
    } else {
      if (context.user) {
        showFirstMessage();
        //ShowMyAccount();
      } else {
        showFirstMessage();
      }
    }

    var body = document.body,
      html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if(isBrowser){
      var workAreaDiv = document.getElementById("workArea");
      if (workAreaDiv !== undefined) {
        
      
        var mainNavBarDiv = document.getElementById("mainNavBar");

        // console.log(
        //   "body.scrollHeight: " +
        //     body.scrollHeight +
        //     ",body.offsetHeight: " +
        //     body.offsetHeight +
        //     ",window.innerHeight: " +
        //     window.innerHeight +
        //     ",mainNavBarDiv.offsetHeight: " +
        //     mainNavBarDiv.offsetHeight +
        //     ",html.scrollHeight: " +
        //     html.scrollHeight +
        //     ",html.clientHeight: " +
        //     html.clientHeight +
        //     ",html.offsetHeight: " +
        //     html.offsetHeight
        // );

        if (mainNavBarDiv !== undefined) {
          if (
            document.getElementsByClassName("PersonalZoneWrapper").length > 0
          ) {
            var pzw = document.getElementsByClassName("PersonalZoneWrapper")[0];
            console.log("pzw.offsetHeight: " + pzw.offsetHeight);

            let desktopAddion = 0;
            if(isBrowser)
            {
              desktopAddion = 320;
            }

            workAreaDiv.style.height =
              (pzw.offsetHeight + desktopAddion).toString() + "px";
          } else {
            if (
              document.getElementsByClassName("AppTrackFormDesc").length === 0
            ) {
              workAreaDiv.style.height =
                (
                  (window.innerHeight) -
                  mainNavBarDiv.offsetHeight -
                  2
                ).toString() + "px";
            }

            console.log("window.innerHeight:" + window.innerHeight + ",mainNavBarDiv.offsetHeight:" + mainNavBarDiv.offsetHeight + ",workAreaDiv.style.height:" + workAreaDiv.style.height);
          }
        } else {
        // alert("mainNavBarDiv not found");
        }
      }
    }

    // console.log("homeCONT=", context);
    // if (context.user) {
    //   ShowMyAccount();
    // }
    //console.log("in use effect");
    //setWellcomeVisibility(false);
    // setWellcome(true);false
  });

  console.log("MENUES=", menues);

  return (
    // <SideMenuContext.Consumer>
    //   {(sideMenuContext) => (
    <div>
   
      <div id="SideBarMenuDiv" className="SideBarMenuDiv">
        {
          <SideBar
            handleClick={hideFirstMessage}
            currentLocation={location.pathname}
          />
        }
      </div>
 
      <div id="workArea">
       {showWellcome === true ? (
        
              <div id="wellcomeDiv">
                <WellcomeComponent showWellcome={showWellcome} />
              </div>
        ) : null} 
        {menues.map((mItem) => (
          <Route
            path={mItem.to}
            key={mItem.id}
            component={componentsMap[mItem.objectName]}
          />
        ))}
        <Route path="/home/login/1" key="20" component={Login} />
        <Route path="/home/login/2" key="25" component={Login} />
        <Route path="/home/register" key="30" component={Register} />
        <Route path="/home/forgotpwd" key="40" component={ForgotPassword} />
        <Route
          path="/home/resetPwd/:token?"
          key="50"
          component={ResetPassword}
        />
        <Route
          path="/home/serviceshelp/:type?"
          key="60"
          component={ServicesHelp}
        />
      </div>
    </div>
  );
};

// Home.contextType = UserContext;
export default Home;
