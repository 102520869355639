import React, { Component } from "react";
import {isBrowser,isMobile} from 'react-device-detect';

import Form from "./form";
import UserContext from "./../../context/userContext";
import { updateUser } from "../../services/userService";
import { getPaymentDNReport } from "../../services/reportService";
import { getSessionUser } from "../../services/sessionServices";

import {
  loadImage,
  getTitleMessages,
  showProgressBar,
  hideProgressBar,
} from "../../services/uiService";

import { search } from "../../services/searchService";

import { Link } from "react-router-dom";
import { getLandRegistarReport } from "../../services/reportService";

class OnlinePaymentsHistory extends Component {
  static contextType = UserContext;
  state = {
    data: {
      userID: this.context ? this.context.UID : "0",
      // ownerId: this.context ? this.context.ownerId : "0",
    },
    paramsData: [{ FieldName: "userID", FieldType: "Number" }],
    additionalSearch: [{ SearchKey: "ONLINE_PAYMENTS_HISTORY" }],

    errors: {},
    tokenError: "",
    toggleSection: false,
    toggleCurrentClass: "CloseSectionDiv",
    toggleSubSection: false,
    toggleSubSectionCurrentClass: "CloseSubSectionDiv",
  };

  schema = {};
  state = {
    data: {
      userID: this.context ? this.context.UID : "0",
      //  ownerId: this.context ? this.context.ownerId : "0",
    },
    paramsData: [{ FieldName: "userID", FieldType: "Number" }],
    additionalSearch: [
      { SearchKey: "ONLINE_PAYMENTS_HISTORY" },
      // { SearchKey: "OWNER_LANDS_REGISTER_PRINTOUTS_RECEIPTS" },
    ],

    errors: {},

    tokenError: "",
    toggleSection: false,
    toggleCurrentClass: "CloseSectionDiv",
    toggleSubSection: false,
    toggleSubSectionCurrentClass: "CloseSubSectionDiv",
  };

  componentDidMount() {}

  changeToggle = async () => {
    const onlinePaymentsHistory = this.context.onlinePaymentsHistory;
    if (
      onlinePaymentsHistory !== undefined &&
      onlinePaymentsHistory != null &&
      onlinePaymentsHistory.length > 0
    ) {
      console.log("Not Undefined or Not Null");
    } else {
      console.log("Undefined or Null");
      await this.getData();
    }

    //  const toggle = !this.state.toggleSection;
    //    const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";

    const toggle = !this.context.onlinePaymentsHistoryInitialToggle;
    this.context.onlinePaymentsHistoryInitialToggle = toggle;

    const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";
    this.context.onlinePaymentsHistoryToggleClass = toggle
      ? "OpenSectionDiv"
      : "CloseSectionDiv";

    if(isBrowser){
      var workAreaDiv = document.getElementById("workArea");
      if (workAreaDiv !== undefined) {
        var myProps = document.getElementById("OnlinePaymentsHistoryDiv");
        if (myProps !== undefined) {
          if (className === "OpenSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              workAreaDiv.offsetHeight + myProps.offsetHeight * 16 + "px";
          }
          if (className === "CloseSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              workAreaDiv.offsetHeight - myProps.offsetHeight * 16 + "px";
          }
        }
      }
    }

    this.setState({
      //toggleSection: toggle,
      // toggleCurrentClass: className,
      toggleSection: this.context.onlinePaymentsHistoryInitialToggle,
      toggleCurrentClass: this.context.onlinePaymentsHistoryToggleClass,
    });
  };

  getData = async () => {
    const data = { ...this.state.data };

    console.log(data);

    const { paramsData, additionalSearch } = this.state;
    try {
      //if (ownerID != "0") {
      console.log("GET_DATA =", data);
      console.log("GET_DATA =", paramsData);
      console.log("GET_DATA =", additionalSearch);

      const SearchResponse = await search(
        data,
        "",
        paramsData,
        additionalSearch
      );
      console.log("Land register GET_DATA =", SearchResponse);

      const searchResult = SearchResponse.data["searchResult"];
      const additionSearch = SearchResponse.data["additionSearch"];
      const searchStatus = SearchResponse.data["searchStatus"];
      const message = SearchResponse.data["message"];

      if (searchStatus === "OK") {
        let additionSearchJSON = "";
        if (additionSearch) {
          additionSearchJSON = JSON.parse(additionSearch);
          if (additionSearchJSON.Table1) {
            this.context.onlinePaymentsHistory = additionSearchJSON.Table1;
            //this.context.handleOnlinePaymentsHistory(additionSearchJSON.Table1);
          } else {
            //   this.context.handleOnlinePaymentsHistory([]);
          }
        }

        console.log(
          "GET_DATA this.context.landsRegisterHistory=",
          this.context.onlinePaymentsHistory
        );
        // console.log(
        //   "GET_DATA this.context.ownerDemandNotices=",
        //   this.context.landsRegisterPayments
        // );
      }
    } catch (ex) {
      console.log("Online Payment History Error", ex);
    }
  };

  render() {
    let onlinePaymentsHistory = [];
    const documentImage = loadImage("Document.svg");

    const userID = this.context.UserId;
    //const paymentImage = loadImage("ic-ecommerce-card.svg");

    if (
      typeof this.context.onlinePaymentsHistory !== "undefined" ||
      this.context.onlinePaymentsHistory != null
    ) {
      onlinePaymentsHistory = this.context.onlinePaymentsHistory;
      console.log(
        "onlinePaymentsHistory 1=",
        this.context.onlinePaymentsHistory
      );
      console.log("ownerProperties 2=", onlinePaymentsHistory);
    }

    console.log("this.state.toggleSection=", this.state.toggleSection);

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <React.Fragment>
            <div className="SectionDiv" onClick={this.changeToggle}>
              <div
                className={this.context.onlinePaymentsHistoryToggleClass}
              ></div>
              <div className="SectionTitle">Online Payments</div>
            </div>
            <div>
              {isBrowser && this.context.onlinePaymentsHistoryInitialToggle &&
              onlinePaymentsHistory.length > 0 ? (
                <div className="d-block">
                  <div className="FirstSubSectionTitleRow row">
                    <div className="FirstSubSectionTitle col">
                      Demand notice
                    </div>
                    <div className="FirstSubSectionTitle col">From</div>
                    <div className="col FirstSubSectionTitle">To</div>
                    <div className="col FirstSubSectionTitleRight">
                      Debit (K)
                    </div>
                    <div className="col FirstSubSectionTitleRight">
                      Paid (K)
                    </div>
                    <div className="col FirstSubSectionTitle">Payment date</div>
                    <div className="col FirstSubSectionTitle">Receipt</div>
                  </div>
                  {onlinePaymentsHistory.map((item) => {
                    return (
                      <div>
                        <div className="row FirstSubSectionDataRow">
                          <div className="FirstSubSectionData col">
                            {item.DEMANDNOTICENUMBER}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.FROMDATE}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.TODATE}
                          </div>
                          <div className="FirstSubSectionRightData col">
                            {item.DEBTAMOUNT}
                          </div>
                          <div className="FirstSubSectionRightData col">
                            {item.PAIDAMOUNT}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.ACTION_DATE}
                          </div>

                          <div className="FirstSubSectionData col">
                            <span className="documentImageSpan">
                              {item.ZILMIS_RC_ID ? (
                                <a target="_blank">
                                  <span className="documentImageSpan">
                                    <img
                                      src={documentImage}
                                      className="documentImage"
                                      onClick={async () => {
                                        showProgressBar();

                                        await getPaymentDNReport(
                                          item.ZILMIS_RC_ID
                                        );

                                        hideProgressBar();
                                      }}
                                    />
                                  </span>
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : isBrowser && this.context.onlinePaymentsHistoryInitialToggle &&
                onlinePaymentsHistory.length === 0 ? (
                <div className="NoDataMessage">
                  {getTitleMessages("NO_ONLINE_PAYMENTS_MESSAGE")["Message"]}
                </div>
              ) : (
                ""
              )}
              {
               isMobile && this.context.onlinePaymentsHistoryInitialToggle &&
               onlinePaymentsHistory.length > 0 ? (
                onlinePaymentsHistory.map((item) => {
                  return (
                    <div style={{borderBottom:"0.01rem solid lightgrey",marginBottom:"0.5rem"}}>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Demand notice</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.DEMANDNOTICENUMBER}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">From</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.FROMDATE}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">To</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.TODATE}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Debit (K)</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.DEBTAMOUNT}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Paid (K)</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.PAIDAMOUNT}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Payment date</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.ACTION_DATE}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Receipt</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">
                          <span className="documentImageSpan">
                            {item.ZILMIS_RC_ID ? (
                              <a target="_blank">
                                <span className="documentImageSpan">
                                  <img
                                    src={documentImage}
                                    className="documentImage"
                                    onClick={async () => {
                                      showProgressBar();

                                      await getPaymentDNReport(
                                        item.ZILMIS_RC_ID
                                      );

                                      hideProgressBar();
                                    }}
                                  />
                                </span>
                              </a>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )})  
               ) : isMobile && this.context.onlinePaymentsHistoryInitialToggle &&
                 onlinePaymentsHistory.length === 0 ? (
                 <div className="NoDataMessage">
                   {getTitleMessages("NO_ONLINE_PAYMENTS_MESSAGE")["Message"]}
                 </div>
               ) : (
                 ""
               ) 
              }
            </div>
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

OnlinePaymentsHistory.contextType = UserContext;
export default OnlinePaymentsHistory;
