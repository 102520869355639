import jwtDecode from "jwt-decode";
//import config from "../config.json";

//const { usersEndpoint } = config;

export function getSessionUser() {
  try {
    const token = sessionStorage.getItem("token");
    if(token != null)
    {
      const user = jwtDecode(token);
      return user;
    }
    else{
      return null;
    }
  } catch (ex) {
    console.log("getSessionUser", ex);
    return null;
  }
}
