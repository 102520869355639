import React, { Component } from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import UserContext from "../context/userContext";
import AppTrackLocateApp from "./forms/AppTrackLocateApp";
import AppTrackGetStatus from "./forms/AppTrackGetStatus";

import ErrorPopupForm from "./forms/errorPopupForm";
import "../styles/applicationTraking.css";

class ApplicationTraking extends Component {

  static contextType = UserContext;
  locateApp = "locateApp";
  getAppStatus = "getAppStatus";
  showError = "ShowError";
  errorPageOpened = '';
  paymentPageId = 'pageAnimationApplicationTraking';
  state = {
    propertyNum: "",
    stage: 1,
    show: this.locateApp,
    // show: this.props.match.params.show
    //   ? this.props.match.params.show
    //   : this.searchForm,
    errorTitle: "",
    errorMessage: "",
    errorLinkTo: "",
    errorLinkTitle: "",
    errorLinkClassName: "",
    ownerId: "",
    webUserId: "",
    searchResult:"",
    additionSearchResults:"",
  };

  constructor(props) {
    super(props);

    this.state = {
      propertyNum: "",
      stage: 1,
      show: this.props.match.params.show
        ? this.props.match.params.show
        : this.locateApp,
      errorTitle: "",
      errorMessage: "",
      ownerId: "",
      webUserId: "",
      isFlushed: false,
    };
    console.log("IN CONSTRAVCTOR");
    //  let logintype = this.props.match.params.show;
    this.checkStageClass = this.checkStageClass.bind(this);
    this.endCurrentStage = this.endCurrentStage.bind(this);
    this.updateAppTrackResults = this.updateAppTrackResults.bind(this);
    this.clickSearchLand = this.clickSearchLand.bind(this);
    this.updateErrorMessage = this.updateErrorMessage.bind(this);
    this.backToPrevStage = this.backToPrevStage.bind(this);
  }

  componentDidMount() {

    //This part is for disable animation after closing errorPopup component 
    this.errorPageOpened = localStorage.getItem('AlertWindow');
    console.log('this.errorPageOpened: ' + this.errorPageOpened);
    this.paymentPageId = this.errorPageOpened !== undefined && this.errorPageOpened !== null ? 'LandRegistryPage' : 'LandRegistryPageAnimation';
    localStorage.removeItem('AlertWindow');

    //if(this.paymentPageId === "LandRegistryPageAnimation")
    //{
      const timer = setTimeout(() => 
      {
        var content = document.getElementById('Content');
        content.style.visibility = 'visible';
        clearTimeout(timer);
      }, 600);
    //}
    //else{
    //  document.getElementById('Content').style.visibility = 'visible';
    //}
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isFlushed) {
    this.setState({isFlushed: false});
    //window.location.href = window.location.href;
  }
  // since we assigned the location.state in <Link>, if we see this prop, and the data is not flushed yet, then flush data, in this case we call loadDeals(), which is a redux action
    if (!this.state.isFlushed && nextProps.location.state === 'flushDeal') {
      this.setState({
        isFlushed: true,
      },() => this.loadDeals());
    }
  }

  loadDeals = () => {
  };

  checkStageClass = (id) => {
    let show = this.state.show;

    let className = "row1  justify-content-center p-1 ";
    if (show === this.locateApp) {
      className += id === "stage1" ? "sel_circle" : "def_circle";
    }
    if (show === this.getAppStatus) {
      className += id === "stage2" ? "sel_circle" : "def_circle";
    }

    //console.log("show", show);
    //console.log("className", className);

    return className;
  };

  checkStageDescClass = (id) => {
    let show = this.state.show;

    let className = "form-row justify-content-left  p-0 ";
    if (show === this.locateApp) {
      className += id === "stageDesc1" ? "sel_desc" : "def_desc";
    }
    if (show === this.getAppStatus) {
      className += id === "stageDesc2" ? "sel_desc" : "def_desc";
    }

    return className;
  };

  endCurrentStage = (showStage) => {
    this.setState((currentState) => {
      return { show: showStage };
    });
  };

  updateAppTrackResults = (searchResultsTable, additionSearchResults) => {
    this.setState({
      searchResult: searchResultsTable,
      additionSearchResults: additionSearchResults,
    });
  };

  updateErrorMessage = (
    title,
    message,
    linkTo = "",
    linkTitle = "",
    linkClass = ""
  ) => {
    console.log("Error=" + title + ",,," + message);
    this.setState({
      errorTitle: title,
      errorMessage: message,
      errorLinkTo: linkTo,
      errorLinkTitle: linkTitle,
      errorLinkClassName: linkClass,
    });
  };

  backToPrevStage = (prevStage, propNum) => {
    console.log("prevStage=" + prevStage + "," + propNum);
    //this.SetState({ show: prevStage, propertyNum: propertyNum });
    this.setState({
      show: prevStage,
      propertyNum: propNum,
    });

    // this.setState((currentState, prop) => {
    //   return { show: prevStage, propertyNum: propertyNum };
    // });
    let p = this.state.propertyNum;
    console.log("p=" + p);
  };

  clickSearchLand = ({ currentTarget: input }) => {
    
  };

  changePaymentForm = () => {
    console.log("changFirst function:");
    this.setState({ show: this.paymentsConfirm });
  };

  render() {
    const { show} = this.state;
    return(
      <React.Fragment>
        <div id="AppTrackWrapper">
          <div id="ApplicationTrakingPage">
            <div className="ApplicationTrakingTitle">Application File Tracking</div>
              <div id="AppTrackingMain">
                <div id="Content">
                <MobileView>
                  <div className="LandRegistryComponentTitle">
                    {show === this.locateApp ? 
                      ("Search application") : ("")  
                    }
                    {show === this.getAppStatus ? 
                      ("Get status") : ("")  
                    }
                  </div>
                
                  {show !== this.showError ? (
                    <div className="LandRegistryStages">
                      <div id="LandRegistryStages">
                        <div
                          id="stage1"
                          className={this.checkStageClass("stage1")}
                        >
                          <div className="stageNum">1</div>
                        </div>

                        <div className="row1">
                          <div className="stage_line"></div>
                        </div>
                        <div
                          id="stage2"
                          className={this.checkStageClass("stage2")}
                        >
                          <div className="stageNum">2</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </MobileView>
                  <div id="LandRegistryData">
                    {show === this.locateApp ? (
                      <div>
                        <AppTrackLocateApp
                          updateShowCase={this.endCurrentStage}
                          nextStage={this.getAppStatus}
                          errorStage={this.showError}
                          updateAppTrackResults={this.updateAppTrackResults}
                          updateErrorMessage={this.updateErrorMessage}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                      {show === this.getAppStatus ? (
                      <div>
                        <AppTrackGetStatus
                          errorStage={this.showError}
                          searchResult={this.state.searchResult}
                          additionSearchResults={this.state.additionSearchResults}
                          updateErrorMessage={this.updateErrorMessage}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                     
                  </div>
                    {show === this.getAppStatus ? (
                        <div>
                        </div>
                      ) : ("")}
                      <BrowserView>
                        {show !== this.showError ? (
                          <div className="LandRegistryStages">
                            <div id="LandRegistryStages">
                              <div id="stage1" className={this.checkStageClass("stage1")}>
                                <div className="stageNum">1</div>
                              </div>

                              <div className="row1">
                                <div className="stage_line"></div>
                              </div>
                              <div id="stage2" className={this.checkStageClass("stage2")}>
                                <div className="stageNum">2</div>
                              </div>
                            </div>
                            <div id="LandRegistryStagesDescription">
                              <div
                                id="stageDesc1"
                                className={this.checkStageDescClass("stageDesc1")}
                              >
                                {" "}
                                <span>Search <br/> application</span>
                              </div>
                              <div className="rowStage mt-4"></div>
                              <div
                                id="stageDesc2"
                                className={this.checkStageDescClass("stageDesc2")}
                              >
                                {" "}
                                <span>Get status</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </BrowserView>
                      <input
                        type="Hidden"
                        name="DpfFileUrlHidden"
                        id="DpfFileUrlHidden"
                      />
                </div>
              </div>
          </div>
          {show === this.showError ? (
              <div>
                <ErrorPopupForm
                  errorTitle={this.state.errorTitle}
                  errorMessage={this.state.errorMessage}
                  errorLinkTo={this.state.errorLinkTo}
                  errorLinkTitle={this.state.errorLinkTitle}
                  errorLinkClassName={this.state.errorLinkClassName}
                />
              </div>
            ) : (
              ""
            )}
        </div>  
      </React.Fragment>
    );
  }
}
ApplicationTraking.contextType = UserContext;
export default ApplicationTraking;
