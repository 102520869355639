import React from "react";

function WithTooltip(Component) {
  return class WithTooltip extends React.Component {
    state = { showTooltip: false };

    mouseOver = () => {
      this.setState({ showTooltip: true });
      // console.log("Mouseover , showTooltip=", this.state.showTooltip);
    };

    mouseOut = () => {
      this.setState({ showTooltip: false });
      // console.log("mouseOut , showTooltip=", this.state.showTooltip);
    };

    render() {
      return (
        <div
          className="SearchResultItemToolTipWrapper"
          onMouseOver={this.mouseOver}
          onMouseOut={this.mouseOut}
        >
          <Component {...this.props} showTooltip={this.state.showTooltip} />
        </div>
      );
    }
  };
}

export default WithTooltip;
