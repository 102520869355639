import React, { Component } from "react";
import Form from "./form";
import Joi from "joi-browser";
import { logintk, verifytk, resendtk } from "../../services/authService";

// import { showProgressBar } from "../../services/uiService";
import {
  showProgressBar,
  getErrorMessages,
  hideProgressBar,
} from "../../services/uiService";

class VerificationPopup extends Form {
  state = {
    data: {
      verificationCode: "",
    },

    confirmationMessage: this.props.confirmationMessage,
    toggle: this.props.toggle,
    refCode: this.props.refCode,
    closeVerificationForm: this.props.closeVerificationForm,
    setVerificationCode: this.props.setVerificationCode,
    resendCode: this.props.resendCode,
    NationalIdOrFullName: this.props.NationalIdOrFullName,
    CellularOrEmail: this.props.CellularOrEmail,
    IdentityUserBy: this.props.IdentityUserBy,
    TokenTargetType: this.props.TokenTargetType,
    TmpSessionId: this.props.TmpSessionId,
    ConfirmationMessage: "",
    errors: {},
  };

  schema = {
    // verificationCode: Joi.string().required().label("Verification code"),
    verificationCode: Joi.string()
      //.min(2)
      .label("Verification code")
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.base":
              err.message = "Please enter Verificaton Code";
              break;
            case "any.empty":
              err.message = "Please enter Verificaton Code";
              break;
            case "any.required":
              err.message = "Please enter Verificaton Code";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  };

  componentDidMount() {
    this.showContentAfterAnimation();
  }

  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  ResendToken = async () => {
    console.log("RESEND TOKEN PARAM=", this.state.NationalIdOrFullName);
    console.log("RESEND TOKEN PARAM=", this.state.CellularOrEmail);
    console.log("RESEND TOKEN PARAM=", this.state.IdentityUserBy);
    console.log("RESEND TOKEN PARAM=", this.state.TokenTargetType);
    console.log("RESEND TOKEN PARAM=", this.state.TmpSessionId);
    try {
      const { data } = this.state;

      showProgressBar();
      /**
        *  token = tokenString,
                          verificationcode = verificationCode,
                          tmpSessionId = tmpSession.Id.ToString(),
                          expiration = DateTime.Now.AddMinutes(30),
                          status = "OK",
                          message = sendVerificationResult.ResultMessage
        */
      const { data: token } = await resendtk(
        this.state.NationalIdOrFullName,
        this.state.CellularOrEmail,
        this.state.IdentityUserBy,
        this.state.TokenTargetType,
        this.state.TmpSessionId
      );

      console.log("Submited result", token);
      // console.log("Submited result 1 ", token["token"]);
      // console.log("Submited result 2", token["expiration"]);
      // console.log("Submited result 3", token["verificationcode"]);
      // console.log("Submited result 4", token["status"]);
      // console.log("Submited result 5", token["message"]);
      // console.log("Submited result 6", exparation);

      //   localStorage.setItem("token", token["token"]);
      //   localStorage.setItem("tokenExparation", token["expiration"]);
      if (token["status"] === "OK") {
        // console.log("before popunSeen");
        this.setState({
          refCode: token["verificationcode"],
          // VerificationCode: token["verificationcode"],
          ConfirmationMessage: token["message"],
          TmpSessionId: token["tmpSessionId"],
          // popupSeen: !this.state.popupSeen,
        });

        //   this.setState({ tokenError: "" }, { error: "" }, { errors: {} });
        // this.setState({ tokenError: "" });
        // this.props.setVerificationCode(
        //   this.state.NationalIdOrFullName,
        //   this.state.CellularOrEmail,
        //   this.state.IdentityUserBy,
        //   this.state.TokenTargetType,
        //   this.state.VerificationCode,
        //   this.state.ConfirmationMessage,
        //   this.state.TmpSessionId
        // );
        this.props.resendCode(this.state.refCode);

        //        this.togglePopup.bind(this);

        //      console.log("after popunSeen");
      } else {
        let errors = { ...this.state.errors };
        errors.CellularOrEmai = token["message"];

        if (token["status"] === "SEND_ERROR") {
          if (
            token["message"] ==
            "Error while parsing XML: Message must contain at least one valid destination"
          ) {
            let error = "Please enter correct mobile number";
            var phoneNumberTxt = document.getElementById("CellularOrEmail");
            phoneNumberTxt.style.border = "1px solid red";
            var errorDiv = phoneNumberTxt.parentElement.children[1];
            if (errorDiv !== undefined) {
              errorDiv.style.visibility = "visible";
            }
            this.setState({ errorMessage: error });
          } else {
            const tokenError = token["message"];
            this.setState({ errorMessage: tokenError });
          }
        } else if (token["status"] === "USER_ERROR") {
          const userError = getErrorMessages(token["message"]);
          this.setState({ userError: userError });
          // console.log("ERROR MSG=", this.state.userError.Message);
          // console.log("ERROR LINK", this.state.userError.LinkTo);
        } else {
          // errors["CellularOrEmai"] = token["message"];
          const tokenError = token["message"];
          this.setState({ errorMessage: tokenError });
        }
        console.log(this.state.errors);
      }

      //console.log("Submited result", token);
      //   console.log("Submited result 1 ", token["token"]);
      //   console.log("Submited result 2", token["expiration"]);
      //   localStorage.setItem("token", token["token"]);
      //   localStorage.setItem("tokenExparation", token["expiration"]);
      //   console.log("Submited");
      //   window.location = "/";
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.verificationCode = ex.response.data;
        this.setState({ errors: errors });
        console.log(errors);
      }
    }
    hideProgressBar();
  };

  doSubmit = () => {
    console.log("doSubmit");
    console.log("verificationCode=" + this.state.data.verificationCode + "=");
    console.log("refCode=" + this.state.refCode);
    if (this.state.data.verificationCode === this.state.refCode) {
      this.props.toggle(this.state.data.verificationCode);
    } else {
      let errors = { ...this.state.errors };
      errors.verificationCode = getErrorMessages(
        "WrongVerificationCode"
      ).Message;
      const err = getErrorMessages("WrongVerificationCode");
      console.log("err=", err);
      const errorMessage = getErrorMessages("WrongVerificationCode").Message;
      this.setState({ errors: errors, errorMessage: errorMessage });
    }
  };

  render() {
    const { data, errorMessage } = this.state;
    console.log("errorMessage=", errorMessage);
    let errVisibility =
      errorMessage !== undefined && errorMessage.length > 0
        ? "visible"
        : "hidden";
    return (
      <React.Fragment>
        <div className="popup">
          <div className="popup_inner">
            {/* <div
                className="smallCloseBtnDivOneTimeLogin"
                onClick={() => {
                  document.getElementById("Cancel_NotSubmitBtn").click();
                }}
              >
                <button
                  className="closeBtn"
                  onClick={() => {
                    document.getElementById("Cancel_NotSubmitBtn").click();
                  }}
                ></button>
              </div> */}

            <div
              className="smallCloseBtnDiv1"
              onClick={() => {
                this.props.closeVerificationForm();
              }}
            >
              <button
                className="closeBtn"
                onClick={() => {
                  this.props.closeVerificationForm();
                }}
              ></button>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="ErrorPopupTitle">Enter Token</div>
              <div id="LoginContent">
                <div className="ErrorPopupMessageToken">
                  {this.props.confirmationMessage}
                </div>
                <div
                  id="ErrorMessageDiv"
                  className="ErrorMessageDivTokenError"
                  style={{ visibility: errVisibility }}
                >
                  {errorMessage !== undefined && errorMessage.length > 0 && (
                    <span>
                      <span className="ErrorMessageExclamationMark">!</span>
                      {errorMessage}
                    </span>
                  )}
                </div>
                {/* <div className="d-block"> */}
                <div className="row ml-2 mt-2" id="TokenDiv">
                  {/* <div className="col">
                      <label>Token</label>
                    </div> */}

                  <div className="col">
                    {this.renderInput(
                      "verificationCode",
                      "",
                      "",
                      "text",
                      "Enter Token",
                      "",
                      "",
                      "true"
                    )}
                  </div>
                  {/* </div> */}
                  {/* <div className="row ml-2 mt-2" id="TokenDiv">
                    {this.renderInput(
                      "verificationCode",
                      "Token",
                      "",
                      "text",
                      "Enter Token",
                      "",
                      "",
                      "true"
                    )}
                  </div> */}
                  {/* <div id="VerificationCodeButtonsDiv">
                    {this.renderButton("Continue", "btnConfirm")}
                  </div> */}
                  {/* <div className="row">
                    <div className="col"> */}
                  <div
                    className="FormButtonsVerificationDiv"
                    id="VerificationCodeButtonsDiv"
                  >
                    {this.renderNotSubmitButton(
                      "Resend Token",
                      "btnCancel",
                      this.ResendToken
                    )}

                    {this.renderButton("Continue", "btnConfirm")}
                  </div>
                </div>
              </div>

              {/* </div>
              </div> */}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default VerificationPopup;

// import React, { Component } from "react";

// class VerificationPopup extends Component {
//   handleClick = () => {
//     this.props.toggle();
//   };
//   render() {
//     return (
//       <div className="popup">
//         <div className="popup_inner">
//           {/* <span className="close" onClick={this.handleClick}>
//             &times;
//           </span> */}
//           <form>
//             <h3>Register!</h3>
//             <label>
//               Name:
//               <input type="text" name="name" />
//             </label>
//             <br />
//             <input type="submit" />
//           </form>
//         </div>
//       </div>
//     );
//   }
// }

//export default VerificationPopup;
/*

class Popup extends React.Component {
  render() {
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <h1>{this.props.text}</h1>
        <button onClick={this.props.closePopup}>close me</button>
        </div>
      </div>
    );
  }
}
*/
// import React, { Component } from "react";

// export default class PopUp extends Component {
//   handleClick = () => {
//     this.props.toggle();
//   };

//   render() {
//     return (
//       <div className="modal">
//         <div className="modal_content">
//           <span className="close" onClick={this.handleClick}>
//             &times;
//           </span>
//           <form>
//             <h3>Register!</h3>
//             <label>
//               Name:
//               <input type="text" name="name" />
//             </label>
//             <br />
//             <input type="submit" />
//           </form>
//         </div>
//       </div>
//     );
//   }
// }
