import React, { Component } from "react";
import Form from "./form";
import { login } from "../../services/authService";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
import {
  showProgressBar,
  hideProgressBar,
  getErrorMessages,
} from "../../services/uiService";

class ResetPasswordConfirm extends Component {
  state = {
    token: this.props.token,
    tokenExparation: this.props.tokenExparation,
  };

  componentDidMount() {
    this.showContentAfterAnimation();
  }
  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  doSubmit = async () => {
    try {
      showProgressBar();
      const { token, tokenExparation } = this.state;

      if (token !== undefined) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("tokenExparation", tokenExparation);

        var dateNow = new Date(Date.now());
        var dateExpire = new Date(tokenExparation);
        console.log("dateExpire: " + dateExpire);
        var timeoutMinutes = Math.round(
          (dateExpire.getTime() - dateNow.getTime()) / 1000 / 60
        );

        console.log("Time left:" + timeoutMinutes + " minutes");

        sessionStorage.setItem("TimeoutInSeconds", timeoutMinutes * 60);

        console.log("Submited");

        window.location = "/";
      }
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("FROM BAD REQUEST=", errors);
      }
    }
    hideProgressBar();
  };

  render() {
    return (
      <React.Fragment>
        {isBrowser ? 
        (<div>  <div
          className="smallCloseBtnDiv1"
          onClick={() => {
            this.props.closeResetPwdComformation();
          }}
        >
          <button
            className="closeBtn"
            onClick={() => {
              this.props.closeResetPwdComformation();
            }}
          ></button>
        </div>
        {/* <div className="LogInTitle">Forgot your password</div> */}
        {/* <div id="LoginContent"> */}
        <div className="LogInTitle">Password Updated</div>
        <div className="LogInTitle2">
          We successfully changed your password.
        </div>
        <div className="LoginForm">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col"></div>
              <div className="col">
                <div className="FormButtonsDiv">
                  <button
                    className="btnCancel"
                    onClick={() => {
                      this.props.closeResetPwdComformation();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btnConfirm"
                    onClick={() => {
                      this.doSubmit();
                      this.props.closeResetPwdComformation();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div></div>):(<div> 
        
        <div className="LogInTitle">Password Updated</div>
        <div className="LogInTitle2">
          We successfully changed your password.
        </div>
        <div className="LoginForm">
          <form onSubmit={this.handleSubmit}>
            {/* <div className="row"> */}
              {/* <div className="col"></div> */}
              <div className="col">
                <div className="FormButtonsDiv">
                  <button
                    className="btnCancel"
                    onClick={() => {
                      this.props.closeResetPwdComformation();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btnConfirm"
                    onClick={() => {
                      this.doSubmit();
                      this.props.closeResetPwdComformation();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            {/* </div> */}
          </form>
        </div></div>)}
       
      </React.Fragment>
    );
  }
}

export default ResetPasswordConfirm;
