import React, { Component } from "react";
import Joi from "joi-browser";
import { Link, NavLink } from "react-router-dom";

import Form from "./form";
import UserContext from "./../../context/userContext";
import { updateUser } from "../../services/userService";
import { getSessionUser } from "../../services/sessionServices";
import { getCountryIsoCode } from "../../services/CountryCodes";

import {} from "../../services/uiService";

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  getCountryCallingCode,
  getCountries,
  getCountry,
} from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";

import {
  loadImage,
  hideProgressBar,
  showProgressBar,
  getTitleMessages,
  getErrorMessages,
} from "../../services/uiService";
class PersonalData extends Form {
  static contextType = UserContext;
  Pwd = React.createRef();
  state = {
    data: {
      //UID: "",
      FirstName: this.context.FirstName,
      LastName: this.context.LastName,
      FullNameCompany: this.context.FullNameCompany,

      NID: this.context.NID,
      Email: this.context.Email,
      Mobile: this.context.Mobile,
      Pwd: this.context.Pwd,
      ConfirmPwd: this.context.Pwd,
      // userName: "",5
      // OwnerId: "",
      //  userName: "", password: ""
    },
    tokenError: "",
    isEdit: false,
    currentInputClass: "col-35",
    isSuccessUpdate: false,
    successMessage: "",
    errors: {},
    ifError: false,
    error: "",
    userError: {},
    tokenError: "",
  };

  schema = {
    FullNameCompany: Joi.string()
      .label("Full name/Company")
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.base":
              err.message = "First name should be a type of text";
              break;
            case "any.empty":
              err.message = "Please enter Full name/Company";
              break;
            case "any.required":
              err.message = "Please enter Full name/Company";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    NID: Joi.string().label("National ID/Registration no.").allow("", null),
    Email: Joi.string()
      .label("E-mail")
      .required()
      .email()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Please enter your E-mail address";
              break;
            case "any.required":
              err.message = "Please enter your E-mail address";
              break;
            case "string.email":
              err.message = "Please enter a valid E-mail address";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    Mobile: Joi.string()
      .label("Mobile")
      .required()
      //.regex(RegExp(/^([0-9]{3})?[-\s]?[-\s]?[0-9]{7}$/))
      //.regex(RegExp(/^[+\s](?=.*?[1-9])[0-9()-\s]+$/))
      .regex(RegExp(/^(?=.*?[0-9])[0-9()+-\s]+$/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter mobile number";
              break;
            case "any.empty":
              err.message = "Please enter mobile number";
              break;
            case "string.regex.base":
              err.message =
                // "Please enter a valid mobile number (Correct format: XXX-X-XXXXXX)";
                "Please enter a valid mobile number.";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    Pwd: Joi.string()
      .min(7)
      .max(15)
      .label("Password")
      //.required()
      //.regex(RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,15}$/))
      .regex(RegExp(/^([a-z])([A-Z][0-9]).{7,15}$/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter your password";
              break;
            case "any.empty":
              err.message = "Please enter your password";
              break;
            case "string.min":
              err.message = "Password must be 7 characters or more";
              break;
            case "string.max":
              err.message = "Password must be less than 15 characters";
              break;
            case "string.regex.base":
              err.message =
                "Password must be at least one number and at least one capital letter";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    ConfirmPwd: Joi.string()
      //.min(0)
      // .max(15)
      //.allow("", null)
      .required()
      //  .regex(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: { allowOnly: "Confirm Passwords do not match to Password" },
        },
      })
      .label("Confirm password")
      // .regex(RegExp(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{7,}/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "ss Please enter your password";
              break;
            case "any.empty":
              err.message = "ss enter your password";
              break;
            case "string.min":
              err.message = "ss Password must be 7 characters or more";
              break;
            case "string.max":
              err.message = "Password must be less than 15 characters";
              break;
            case "string.regex.base":
              err.message =
                "Password must be at least one number and at least one capital letter";
              break;
            default:
              err.message = "Confirm Password must be match to Password";
              break;
          }
        });
        return errors;
      }),
  };

  // schema = {
  //   FirstName: Joi.string()
  //     //.min(2)
  //     .label("First name")
  //     .required()
  //     .error((errors) => {
  //       errors.forEach((err) => {
  //         switch (err.type) {
  //           case "string.base":
  //             err.message = "First name should be a type of text";
  //             break;
  //           case "any.empty":
  //             err.message = "Please enter First name";
  //             break;
  //           case "any.required":
  //             err.message = "Please enter First name";
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       return errors;
  //     }),

  //   LastName: Joi.string()
  //     .label("Last name")
  //     .required()
  //     .error((errors) => {
  //       errors.forEach((err) => {
  //         switch (err.type) {
  //           case "string.base":
  //             err.message = "Last name should be a type of text";
  //             break;
  //           // case "string.min":
  //           //   err.message =
  //           //     "Last name should have a minimum length of 2 character";
  //           //   break;
  //           case "any.empty":
  //             err.message = "Please enter Last name";
  //             break;
  //           case "any.required":
  //             err.message = "Please enter Last name";
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       return errors;
  //     }),
  //   NID: Joi.string().label("National ID").allow("", null),
  //   Email: Joi.string()
  //     .label("E-mail")
  //     .required()
  //     .email()
  //     .error((errors) => {
  //       errors.forEach((err) => {
  //         switch (err.type) {
  //           case "any.empty":
  //             err.message = "Please enter your E-mail address";
  //             break;
  //           case "any.required":
  //             err.message = "Please enter your E-mail address";
  //             break;
  //           case "string.email":
  //             err.message = "Please enter a valid E-mail address";
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       return errors;
  //     }),

  //   Mobile: Joi.string()
  //     .label("Mobile")
  //     .required()
  //     //.regex(RegExp(/^([0-9]{3})?[-\s]?[-\s]?[0-9]{7}$/))
  //     // .regex(RegExp(/^[+\s]([0-9]{1,4})?[-\s]?[-\s]?[0-9]{6,15}$/))
  //     //.regex(RegExp(/^[+\s](?=.*?[1-9])[0-9()-\s]+$/))
  //     .regex(RegExp(/^(?=.*?[0-9])[0-9()+-\s]+$/))
  //     .error((errors) => {
  //       errors.forEach((err) => {
  //         switch (err.type) {
  //           case "any.required":
  //             err.message = "Please enter mobile number";
  //             break;
  //           case "any.empty":
  //             err.message = "Please enter mobile number";
  //             break;
  //           case "string.regex.base":
  //             err.message = "Please enter a valid mobile number";
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       return errors;
  //     }),

  //   Pwd: Joi.string()
  //     .min(7)
  //     .max(15)
  //     .label("Password")
  //     //.required()
  //     .regex(RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,15}$/))
  //     //.regex(RegExp(/(?=.*d)(?=.*[a-z][A-Z]).{7,15}/))
  //     .error((errors) => {
  //       errors.forEach((err) => {
  //         switch (err.type) {
  //           case "any.required":
  //             err.message = "Please enter your password";
  //             break;
  //           case "any.empty":
  //             err.message = "Please enter your password";
  //             break;
  //           case "string.min":
  //             err.message = "Password must be 7 characters or more";
  //             break;
  //           case "string.max":
  //             err.message = "Password must be less than 15 characters";
  //             break;
  //           case "string.regex.base":
  //             err.message =
  //               "Password must be at least one number and at least one capital letter";
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       return errors;
  //     }),

  //   ConfirmPwd: Joi.string()
  //     //.min(0)
  //     // .max(15)
  //     //.allow("", null)
  //     .required()
  //     //  .regex(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
  //     .valid(Joi.ref("Pwd"))
  //     .options({
  //       language: {
  //         any: { allowOnly: "Confirm Passwords do not match to Password" },
  //       },
  //     })
  //     .label("Confirm password")
  //     // .regex(RegExp(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{7,}/))
  //     .error((errors) => {
  //       errors.forEach((err) => {
  //         switch (err.type) {
  //           case "any.required":
  //             err.message = "Please enter your password";
  //             break;
  //           case "any.empty":
  //             err.message = "Enter your password";
  //             break;
  //           case "string.min":
  //             err.message = "Password must be 7 characters or more";
  //             break;
  //           case "string.max":
  //             err.message = "Password must be less than 15 characters";
  //             break;
  //           case "string.regex.base":
  //             err.message =
  //               "Password  must be at least one number and at least one capital letter";
  //             break;
  //           default:
  //             err.message = "Confirm Password must be match to Password";
  //             break;
  //         }
  //       });
  //       return errors;
  //     }),
  // };

  changeMobileNumber = (value) => {
    // alert(value);
    //console.log(e.target.value);
    let { data } = this.state;
    if (value !== undefined) {
      data.Mobile = value;
    } else {
      data.Mobile = "";
    }

    this.setState({ data: data });
    //alert("mobiLe=" + this.state.data.mobile + "=");
  };

  doSubmit = async () => {
    try {
      this.setState({
        isSuccessUpdate: false,
        successMessage: "",
        errors: {},
        ifError: false,
        error: "",
        userError: {},
        errorMessage: "",
        tokenError: "",
      });

      this.context.PropertyInitialToggle = false;
      this.context.PropertyInitialToggleClass = "CloseSectionDiv";
      this.context.ownerProperties = [];
      this.context.ownerDemandNotices = [];
      this.context.landsRegisterHistoryInitialToggle = false;
      this.context.landsRegisterHistoryToggleClass = "CloseSectionDiv";

      this.context.onlinePaymentsHistoryInitialToggle = false;
      this.context.onlinePaymentsHistoryToggleClass = "CloseSectionDiv";
      this.context.applicationTrakingHistoryInitialToggle = false;
      this.context.applicationTrakingHistoryToggleClass = "CloseSectionDiv";
      this.context.landsRegisterHistory = [];
      this.context.onlinePaymentsHistory = [];
      this.context.ownerApplications = [];
      this.context.applicationTrakingHistory = [];

      //alert("AFTER RESET 1=" + toString(this.context.PropertyInitialToggle));
      // alert("AFTER RESET 2=" + this.context.PropertyInitialClassName);

      let { data } = this.state;
      showProgressBar();

      const { data: token } = await updateUser(
        this.context.SID,
        this.context.UID,
        data.FirstName,
        data.LastName,
        data.FullNameCompany,
        data.NID,
        data.Email,
        data.Mobile,
        data.Pwd
      );

      hideProgressBar();
      //const { data: token, exparation }

      let { status, message } = token;

      console.log("status", status);
      console.log("message", message);
      console.log("changedUSerToken=", token);

      if (status === "OK") {
        sessionStorage.setItem("token", token["token"]);
        sessionStorage.setItem("tokenExparation", token["expiration"]);

        // this.context.PropertyInitialToggle = false;
        // this.context.PropertyInitialToggleClass = "CloseSectionDiv";

        this.context.handleCurrentUser();
        const successMessage = message;
        this.setState({
          data: data,
          isSuccessUpdate: true,
          successMessage: successMessage,
          isEdit: false,
        });
      } else if (status === "USER_ERROR") {
        const userError = getErrorMessages(message);
        this.setState({
          userError: userError,
          isSuccessUpdate: false,
          successMessage: "",
          isEdit: true,
        });

        console.log("ERROR MSG=", this.state.userError.Message);
        console.log("ERROR LINK", this.state.userError.LinkTo);
      } else {
        // errors["CellularOrEmai"] = token["message"];
        const tokenError = message;
        this.setState({
          errorMessage: tokenError,
          ifError: true,
          isSuccessUpdate: false,
          successMessage: "",
          isEdit: true,
        });
        console.log(this.state.errors);
      }
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        const tokenError = ex;
        this.setState({ errors, tokenError });
        console.log(errors);
      }
    }
  };

  componentDidMount() {
    let user = this.context.user;

    let { data } = this.state;

    data.FirstName = this.context.FirstName;
    data.LastName = this.context.LastName;
    data.FullNameCompany = this.context.FullNameCompany;
    data.NID = this.context.NID;
    data.Mobile = this.context.Mobile;
    data.Email = this.context.Email;
    data.Pwd = this.context.Pwd;
    data.ConfirmPwd = this.context.Pwd;
    this.setState({ data: data });

    this.setState({ data: data });
    console.log("UserContext=", this.context);
  }

  getUserName = () => {
    let user = this.context.user;
    console.log("USER=", user);
    let printedTitle = "";
    if (this.context.FullNameCompany.length > 0) {
      printedTitle = this.context.FullNameCompany;
    } else {
      if (this.context.FirstName.length > 0)
        printedTitle = this.context.FirstName;
      if (this.context.LastName.length > 0)
        printedTitle +=
          printedTitle.length > 0
            ? " " + this.context.LastName
            : this.context.LastName;
      else {
        if (this.context.NID.length > 0) printedTitle = this.context.NID;
        else {
          printedTitle +=
            this.context.Email.length > 0
              ? this.context.Email
              : this.context.Mobile;
        }
      }
    }
    return printedTitle;
  };
  getHelloText = () => {
    let user = this.context.user;
    let printedTitle = "";
    let recTitle = "";
    if (
      this.context !== undefined &&
      this.context.OwnerId !== undefined &&
      this.context.OwnerId != "0"
    )
      //if (this.context.OwnerId.length > 0 && this.context.OwnerId != "0")
      printedTitle = getTitleMessages("PERSONAL_ZONE_ZILMIS_USER_HELLO")[
        "Message"
      ];
    // printedTitle = ", you have recognazed in our system as a Property owner";
    else
      printedTitle = getTitleMessages("PERSONAL_ZONE_WEB_USER_HELLO")[
        "Message"
      ];

    return printedTitle;
  };

  getHelloText1 = () => {
    let user = this.context.user;
    let printedTitle = "";
    let recTitle = "";
    if (
      this.context !== undefined &&
      this.context.OwnerId !== undefined &&
      this.context.OwnerId != "0"
    )
      //if (this.context.OwnerId.length > 0 && this.context.OwnerId != "0")
      printedTitle = getTitleMessages("PERSONAL_ZONE_ZILMIS_USER_HELLO1")[
        "Message"
      ];
    // printedTitle = ", you have recognazed in our system as a Property owner";
    else
      printedTitle = getTitleMessages("PERSONAL_ZONE_WEB_USER_HELLO1")[
        "Message"
      ];

    return printedTitle;
  };

  EditData = (e) => {
    //e.preventDefault();
    this.setState({
      isEdit: !this.state.isEdit,
    });

    let currentInputClass = "";
    if (this.state.isEdit === true) currentInputClass = "col-35";
    else currentInputClass = "col-35 readonlyInput";

    this.setState({ currentInputClass: currentInputClass });
    console.log("In Edit=" + this.state.isEdit + "=");
  };

  handlePersonalDataFormSubmit = (e) => {
    e.preventDefault();
    // alert("handleRegisterFormSubmit");
    let { data, errorMessage } = this.state;
    /*
  fullNameCompany: "",
      nationalIDRegNum: "",
      email: "",
      mobile: "+260",
      password: "",
      confirmPassword: "",

*/
    if (data.FullNameCompany.length === 0) {
      errorMessage = "Please enter Full name/Company.";
      document.getElementById("FullNameCompany").className +=
        " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("FullNameCompany").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("FullNameCompany").className = classname;
    }

    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (data.Email.length === 0) {
      errorMessage = "Please enter your E-mail address.";
      this.showError(errorMessage);
      document.getElementById("Email").className += " ErrorInputFieldStyle";
      return;
    } else if (!mailFormat.test(data.Email)) {
      errorMessage = "Please enter a valid E-mail address.";
      document.getElementById("Email").className += " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("Email").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("Email").className = classname;
    }

    var phoneRegex = /^(?=.*?[0-9])[0-9()+-\s]+$/;

    if (data.Mobile === undefined || data.Mobile.length === 0) {
      errorMessage = "Please enter your mobile number.";
      if (document.getElementById("mobileInput")) {
        document.getElementById("mobileInput").className +=
          " ErrorInputFieldStyle";
      } else if (document.getElementById("mobileInputReadonly")) {
        document.getElementById("mobileInputReadonly").className +=
          " ErrorInputFieldStyle";
      }

      this.showError(errorMessage);

      return;
    } else {
      if (document.getElementById("mobileInput")) {
        let classname = document.getElementById("mobileInput").className;
        classname = classname.replace("ErrorInputFieldStyle", "");
        document.getElementById("mobileInput").className = classname;
      } else if (document.getElementById("mobileInputReadonly")) {
        let classname = document.getElementById("mobileInputReadonly")
          .className;
        classname = classname.replace("ErrorInputFieldStyle", "");
        document.getElementById("mobileInputReadonly").className = classname;
      }
    }

    if (!phoneRegex.test(data.Mobile)) {
      errorMessage = "Please enter a valid mobile number.";
      if (document.getElementById("mobileInput")) {
        document.getElementById("mobileInput").className +=
          " ErrorInputFieldStyle";
      } else if (document.getElementById("mobileInputReadonly")) {
        document.getElementById("mobileInputReadonly").className +=
          " ErrorInputFieldStyle";
      }

      this.showError(errorMessage);
      return;
    } else {
      if (document.getElementById("mobileInput")) {
        let classname = document.getElementById("mobileInput").className;
        classname = classname.replace("ErrorInputFieldStyle", "");
        document.getElementById("mobileInput").className = classname;
      } else if (document.getElementById("mobileInputReadonly")) {
        let classname = document.getElementById("mobileInputReadonly")
          .className;
        classname = classname.replace("ErrorInputFieldStyle", "");
        document.getElementById("mobileInputReadonly").className = classname;
      }
    }

    const isValidPhone = isValidPhoneNumber(data.Mobile);
    if (!isValidPhone) {
      errorMessage = "Please enter a valid mobile number.";
      if (document.getElementById("mobileInput")) {
        document.getElementById("mobileInput").className +=
          " ErrorInputFieldStyle";
      } else if (document.getElementById("mobileInputReadonly")) {
        document.getElementById("mobileInputReadonly").className +=
          " ErrorInputFieldStyle";
      }
      this.showError(errorMessage);
      return;
    } else {
      if (document.getElementById("mobileInput")) {
        let classname = document.getElementById("mobileInput").className;
        classname = classname.replace("ErrorInputFieldStyle", "");
        document.getElementById("mobileInput").className = classname;
      } else if (document.getElementById("mobileInputReadonly")) {
        let classname = document.getElementById("mobileInputReadonly")
          .className;
        classname = classname.replace("ErrorInputFieldStyle", "");
        document.getElementById("mobileInputReadonly").className = classname;
      }
    }

    var passwRegex = /^[A-Za-z0-9]\w{6,14}$/;

    if (data.Pwd.length === 0) {
      errorMessage = "Please enter your password.";
      document.getElementById("Pwd").className += " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("Pwd").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("Pwd").className = classname;
    }

    if (!passwRegex.test(data.Pwd)) {
      errorMessage =
        "Password must be at least 7 characters with numbers and letters.";
      this.showError(errorMessage);
      document.getElementById("Pwd").className += " ErrorInputFieldStyle";
      return;
    } else {
      let classname = document.getElementById("Pwd").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("Pwd").className = classname;
    }

    var pwdCompare =
      this.state.data.Pwd === this.state.data.ConfirmPwd &&
      this.state.data.Pwd != "";

    if (!pwdCompare) {
      errorMessage = "Confirm Password must be match to Password";

      document.getElementById("ConfirmPwd").className +=
        " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("ConfirmPwd").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("ConfirmPwd").className = classname;
    }

    if (this.isSuccessUpdate !== undefined) {
      this.setState({ isSuccessUpdate: false });
    }

    if (this.successMessage !== undefined) {
      this.setState({ successMessage: "" });
    }

    var errorMessageDiv = document.getElementById("ErrorMessageDiv");

    if (errorMessageDiv !== undefined) {
      errorMessageDiv.style.visibility = "hidden";
    }
    this.setState({ errors: {}, errorMessage: "" });

    this.doSubmit();
  };

  showError = (errorMessage) => {
    //  let { data, errors, errorMessage } = this.state;

    if (errorMessage !== undefined && errorMessage.length > 0) {
      this.setState({ errorMessage });
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    } else {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");

      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "hidden";
      }
      this.setState({ errors: {}, errorMessage: "" });
    }
  };

  render() {
    //let user = this.context.user;

    let disable = this.state.isEdit === true ? "enable" : "disabled";

    //let tokenError = this.state.tokenError;
    let { tokenError, isSuccessUpdate, successMessage,isEdit } = this.state;
    const { data, ifError, errorMessage } = this.state;
    const errors = { ...this.state };
    const userError = { ...this.state.userError };
    let countryCode = "";
    let countryCodeISO = "";
    if (data.Mobile !== undefined) {
      countryCodeISO = getCountryIsoCode(data.Mobile);
      console.log("countryCodeISO=", countryCodeISO);
      // countryCode = data.Mobile.substring(0, 3);
      // if (countryCode === "972") countryCodeISO = "IL";
      // else if (countryCode === "260") countryCodeISO = "ZM";
    }

    if (this.state.isEdit) {
      console.log("In Edit");
    } else console.log("NOT In Edit");

    // const countries = getCountries();

    console.log("RENDER userError=", userError);
    console.log("RENDER errorMessage=", errorMessage);

    if (userError !== undefined || errorMessage !== undefined) {
      if (
        (userError !== undefined && userError.Message > 0) ||
        (errorMessage !== undefined && errorMessage.length > 0)
      ) {
        isSuccessUpdate = false;
        successMessage = "";
      }
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");

      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    }

    return (
      <UserContext.Consumer>
        {(UserContext) => (
          <React.Fragment>
            <div className="PersonalDataTitle">
              Hello {this.getUserName()} <b>{this.getHelloText()}</b>{" "}
              {this.getHelloText1()}
              {this.context !== undefined ||
              this.context.OwnerId !== undefined ||
              this.context.OwnerId === 0 ? (
                <p>
                  For more information click
                  <NavLink
                    className="LoginWithPasswordLink"
                    to="/home/serviceshelp/2"
                  >
                    <span> here </span>
                  </NavLink>
                  or
                  <NavLink className="LoginWithPasswordLink" to="/home/contact">
                    <span> contact us</span>
                  </NavLink>
                  .
                </p>
              ) : (
                ""
              )}
            </div>
            <div id="PersonalDataForm">
              <form onSubmit={this.handlePersonalDataFormSubmit} id="PersonalDataForm">
                <div className="row">
                  <div className="col"></div>
                  <div className="col-35">
                    <div
                      id="ErrorMessageDiv"
                      className="ErrorMessageDivPersonalDetails"
                    >
                      {errorMessage !== undefined && errorMessage.length > 0 && (
                        <span>
                          <span className="ErrorMessageExclamationMark">!</span>
                          {errorMessage}
                        </span>
                      )}
                      {userError !== undefined &&
                        userError.Title !== undefined &&
                        userError.Title.length > 0 && <p>{userError.Title}</p>}
                      {userError !== undefined &&
                        userError.Message !== undefined &&
                        userError.Message.length > 0 && (
                          <span>
                            <span className="ErrorMessageExclamationMark">
                              !
                            </span>
                            {userError.Message}
                          </span>
                        )}

                      {userError !== undefined &&
                        userError.LinkTo !== undefined &&
                        userError.LinkTo.length > 0 &&
                        !userError.IsLinkSeparate && (
                          <span>
                            <NavLink
                              className="LoginWithPasswordLink"
                              to={userError.LinkTo}
                            >
                              <span>{userError.LinkTitle}</span>
                            </NavLink>
                          </span>
                        )}
                      {userError.LinkTo !== undefined &&
                        userError.LinkTo.length > 0 &&
                        userError.IsLinkSeparate && (
                          <span>
                            <br />
                            <NavLink
                              className="LoginWithPasswordLink"
                              to={userError.LinkTo}
                            >
                              <span>{userError.LinkTitle}</span>
                            </NavLink>
                          </span>
                        )}
                    </div>
                  </div>
                </div>
    
                <div className="row">
                  <div className="col"></div>
                  <div className="col-35">
                    {isEdit !== true && isSuccessUpdate &&
                      (userError === undefined ||
                        userError.Message === undefined ||
                        userError.Message.length === 0 ||
                        errorMessage === undefined ||
                        errorMessage.length === 0) && (
                        <span className="SuccessMessage">{successMessage}</span>
                      )}
                  </div>
                </div>
                <fieldset disabled={!this.state.isEdit} className="personalDataFieldset">
                  <div className="row">
                    <div className="col" id="NameLabel">
                      <label>* Full name/Company:</label>
                    </div>
                 
                    {this.state.isEdit
                      ? this.renderInput(
                          "FullNameCompany",
                          "",
                          "col-35",
                          "text",
                          "Full name/Company",
                          "",
                          "",
                          "true"
                        )
                      : this.renderReadOnlyInput(
                          "FullNameCompany",
                          "",
                          "col-35 readonlyInput",
                          "text",
                          "Full name/Company",
                          "",
                          "",
                          ""
                        )}
                     
                  </div>

                  <div className="row">
                    <div className="col" id="NIDLabel">
                      <label>National ID/Registration no.:</label>
                    </div>

                    {this.state.isEdit
                      ? this.renderInput(
                          "NID",
                          "",
                          "col-35",
                          "text",
                          "Enter National ID",
                          "",
                          "",
                          ""
                        )
                      : this.renderReadOnlyInput(
                          "NID",
                          "",
                          "col-35",
                          "text",
                          "Enter National ID",
                          "",
                          "",
                          ""
                        )}
                  </div>
                  <div className="row">
                    <div className="col" id="EmailLabel">
                      <label>* E-mail:</label>
                    </div>

                    {this.renderReadOnlyInput(
                      "Email",
                      "",
                      "col-35",
                      "text",
                      "Enter E-mail",
                      "",
                      "",
                      ""
                    )}
                  </div>

                  <div className="row">
                    <div className="col"  id="MobileLabel">
                      <label>* Mobile:</label>
                    </div>

                    {this.state.isEdit ? (
                      <div className="col-35 mobileInput">
                        <PhoneInput
                          id="mobileInput"
                          className="mobileInput"
                          placeholder="Mobile"
                          international
                          defaultCountry={countryCodeISO}
                          value={data.Mobile}
                          onChange={this.changeMobileNumber}
                        />
                      </div>
                    ) : (
                      <div className="col-35 readonlyInput">
                        <PhoneInput
                          id="mobileInputReadonly"
                          placeholder="Mobile"
                          international
                          defaultCountry={countryCodeISO}
                          value={data.Mobile}
                          onChange={this.changeMobileNumber}
                          readonly
                          disabled
                        />
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                  <div className="row">
                    <div className="col" id="PWDLabel">
                      <label>* Password:</label>
                    </div>
                    {this.state.isEdit
                      ? this.renderInput(
                          "Pwd",
                          "",
                          "col-35",
                          "password",
                          "Enter password",
                          "",
                          "",
                          ""
                        )
                      : this.renderReadOnlyInput(
                          "Pwd",
                          "",
                          "col-35",
                          "password",
                          "Enter password",
                          "",
                          "",
                          ""
                        )}
                  </div>
                  <div className="row">
                    <div className="col" id="ConfirmPWDLabel">
                      <label>*Confirm Password:</label>
                    </div>
                    {this.state.isEdit
                      ? this.renderInput(
                          "ConfirmPwd",
                          "",
                          "col-35",
                          "password",
                          "Confirm password",
                          "",
                          "",
                          "",
                          ""
                        )
                      : this.renderReadOnlyInput(
                          "ConfirmPwd",
                          "",
                          "col-35",
                          "password",
                          "Confirm password",
                          "",
                          "",
                          "",
                          ""
                        )}
                  </div>
                </fieldset>

                <div className="FormButtonsDiv FormButtonsPersonalDiv">
                  {this.renderNotSubmitButton(
                    "Edit",
                    "btnCancel",
                    this.EditData
                  )}
                  {this.renderButton("Save", "btnConfirm")}
                  {/* </div>
                  </div> */}
                </div>
              </form>
            </div>
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}
PersonalData.contextType = UserContext;
export default PersonalData;
