import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import ResetPasswordForm from "./forms/resetPasswordForm";
import ResetPasswordConfirm from "./forms/resetPasswordConfirm";
import {isBrowser} from 'react-device-detect';
class ResetPassword extends Component {
  state = {
    showResetPwd: true,
    showResetPwdConfirm: false,
    username: "",
    fname: "",
    lname: "",
    uid: "",
    uemail: "",
    umobile: "",
    uNationalId: "",
    upwd: "",
    sid: "",
    token: "",
    tokenExparation: "",
  };

  constructor(props) {
    super(props);
    this.closeResetPwdForm = this.closeResetPwdForm.bind(this);
    this.showResetPwdConformation = this.showResetPwdConformation.bind(this);
    this.closeResetPwdComformation = this.closeResetPwdComformation.bind(this);

    let token = this.props.match.params.token;
    console.log("changeUser token=", token);

    const changeUser = jwtDecode(token);
    console.log("changeUser=", changeUser);
    this.state = {
      showResetPwd: true,
      showResetPwdConfirm: false,
      username: changeUser["email"],
      fname: changeUser["given_name"],
      lname: changeUser["family_name"],
      uid: changeUser["unique_name"],
      uemail: changeUser["email"],
      umobile: changeUser["nonce"],
      uNationalId: changeUser["nameid"],
      sid: changeUser["sid"],
    };
    console.log("reset state=", this.state);
  }

  closeResetPwdForm = () => {
    this.setState({ showResetPwd: !this.state.showResetPwd });
    window.location.href = window.location.origin + "/home";
  };

  closeResetPwdComformation = () => {
    this.setState({
      showResetPwdConfirm: !this.state.showResetPwdConfirm,
    });
    window.location.href = window.location.origin + "/home";
  };

  showResetPwdConformation = (token, tokenExparation) => {
    this.setState({
      showResetPwd: !this.state.showResetPwd,
      showResetPwdConfirm: !this.state.showResetPwdConfirm,
      token: token,
      tokenExparation: tokenExparation,
    });
  };

  closeEmailForm = () => {
    this.setState({ showForgotPwdEmail: !this.state.showForgotPwdEmail });
    window.location.href = window.location.origin + "/";
  };

  componentDidMount() {
    //let token = this.props.match.params.token;
  }

  render() {
    const {
      showResetPwd,
      showResetPwdConfirm,
      username,
      fname,
      lname,
      uid,
      uemail,
      umobile,
      uNationalId,
      sid,
      token,
      tokenExparation,
    } = this.state;

    return (
      <React.Fragment>
        {isBrowser ? (  <div id="ResetPasswordContainer">
          <div className="popup">
            <div className="popup_inner">
          

              {showResetPwd && (
                <ResetPasswordForm
                  closeResetPwdForm={this.closeResetPwdForm}
                  showResetPwdConformation={this.showResetPwdConformation}
                  username={username}
                  fname={fname}
                  lname={lname}
                  uid={uid}
                  uemail={uemail}
                  umobile={umobile}
                  uNationalId={uNationalId}
                  sid={sid}
                />
              )}

              {showResetPwdConfirm && (
                <ResetPasswordConfirm
                  closeResetPwdComformation={this.closeResetPwdComformation}
                  token={token}
                  tokenExparation={tokenExparation}
                />
              )}
            </div>
          </div>
        </div>):(<div id="ResetPasswordContainer">
         
          

              {showResetPwd && (
                <ResetPasswordForm
                  closeResetPwdForm={this.closeResetPwdForm}
                  showResetPwdConformation={this.showResetPwdConformation}
                  username={username}
                  fname={fname}
                  lname={lname}
                  uid={uid}
                  uemail={uemail}
                  umobile={umobile}
                  uNationalId={uNationalId}
                  sid={sid}
                />
              )}

              {showResetPwdConfirm && (
                <ResetPasswordConfirm
                  closeResetPwdComformation={this.closeResetPwdComformation}
                  token={token}
                  tokenExparation={tokenExparation}
                />
              )}
            </div>
         
        )}
        
      </React.Fragment>
    );
  }
}

export default ResetPassword;
