import React from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import Form from "./form";
import ShortValueField from "../fields/shortValueField";

class AppTrackGetStatus extends Form {
    
    state = {
        searchResult: this.props.searchResult,
        additionSearchResults: this.props.additionSearchResults,
    }

    componentDidMount(){
      var ApplicationTrakingPageHeight = document.getElementById('ApplicationTrakingPage').clientHeight;
      document.getElementById('workArea').style.height = (ApplicationTrakingPageHeight + 50).toString() + 'px';

      console.log('ApplicationTrakingPageHeight: ' + ApplicationTrakingPageHeight);
    }

    render() {
        const {
            searchResult,
            additionSearchResults,
          } = this.state;
      
          let landName = "";
          let appNumber = "";
          let workflow = "";
          let createdDate = "";
          let checkinDate = "";
          let unit = "";
          let status = "";
          let additionSerchJSON = null;
          let lastRowNumber = 0;
          var arr = [];
          let showGrid = false;

          if (searchResult) {
            additionSerchJSON = JSON.parse(additionSearchResults);

            lastRowNumber = additionSerchJSON["Table1"].length -1;

            if(lastRowNumber === 0)
            {
              showGrid = additionSerchJSON["Table1"][lastRowNumber]["STATUS"] !== 'AppNoFolder';
              landName = additionSerchJSON["Table1"][lastRowNumber]["LAND_NO"];
              appNumber = additionSerchJSON["Table1"][lastRowNumber]["APPLICATIONNUMBER"];
              workflow = additionSerchJSON["Table1"][lastRowNumber]["WORKFLOW"];
              createdDate = additionSerchJSON["Table1"][lastRowNumber]["CREATED"];
            }
            else{
              showGrid = true;
            }

            if(showGrid)
            {
              landName = additionSerchJSON["Table1"][lastRowNumber]["LAND_NO"];
              appNumber = additionSerchJSON["Table1"][lastRowNumber]["APPLICATIONNUMBER"];
              workflow = additionSerchJSON["Table1"][lastRowNumber]["WORKFLOW"];
              createdDate = additionSerchJSON["Table1"][lastRowNumber]["CREATED"];
              checkinDate = additionSerchJSON["Table1"][lastRowNumber]["CHECKINDATE"];
              unit = additionSerchJSON["Table1"][lastRowNumber]["UNIT"];
              status = additionSerchJSON["Table1"][lastRowNumber]["STATUS"];
  
              console.log('workflow: ' + workflow + ', checkinDate: ' + checkinDate + ', unit: ' + unit + ', status: ' + status);
  
              console.log('additionSerchJSON: ' + additionSerchJSON["Table1"]);
  
              Object.keys(additionSerchJSON["Table1"]).forEach(function(key) {
                  arr.push(additionSerchJSON["Table1"][key]);
                });
  
              console.log('arr: ' + arr);
            }
          }
          

        return (
          <React.Fragment>
              <div className="AppTrackFormDesc">
                {
                  showGrid === true ?
                  (
                    <div>Application status for property <span className="AppTrackFormDescBold">{landName}</span>.
                    <div>Your application for <span className="AppTrackFormDescBold">{workflow} ({appNumber})</span>&nbsp;dated <span className="AppTrackFormDescBold">{createdDate}</span>
                    &nbsp; is handled by the <span className="AppTrackFormDescBold">{unit}</span>,
                    {
                      status != null ?
                      (
                        <span> with status <span className="AppTrackFormDescBold">{status}</span>.</span>
                        ) : 
                      (
                        <span></span>
                      )
                    } 
                    </div>
                    </div>
                  ) :
                  (
                    <div>Application status for property <span className="AppTrackFormDescBold">{landName}</span>.
                    <div>Your application for <span className="AppTrackFormDescBold">{workflow} ({appNumber})</span>&nbsp;dated <span className="AppTrackFormDescBold">{createdDate}</span>
                    &nbsp; is handled by the <span className="AppTrackFormDescBold">Ministry of Lands and Natural Resources</span>.
                    <div>For more information please <a href="/home/contact">contact us</a>.</div>
                    </div>
                    </div>
                  )
                }
              </div>
              {
                  showGrid === true ?
                  (
<div className="AppTrackFormDesc AppTrackScrollbar">
                <div className="FirstSubSectionAppTrackTitleRow row">
                    <div className="FirstSubSectionTitle col">Date</div>
                    <div className="FirstSubSectionTitle col2">Unit</div>
                    <div className="FirstSubSectionTitle col2">Status</div>
                </div>
                <BrowserView>
                  <div>
                  {
                      arr.map((item) => {
                          return (
                          <div className="row FirstSubSectionAppTrackDataRow">
                              <div className="FirstSubSectionData col">{item.CHECKINDATE}</div>
                              <div className="FirstSubSectionData col2">
                                  {item.UNIT != null && item.UNIT.length >
                                          23 ? (
                                            <ShortValueField
                                              shortValue={
                                                  item.UNIT.substring(
                                                  0,
                                                  23
                                                ) + " ..."
                                              }
                                              fullValue={
                                                  item.UNIT
                                              }
                                            />
                                          ) : (
                                            item.UNIT
                                          )}
                              </div>
                              <div className="FirstSubSectionData col2">
                              {item.STATUS != null && item.STATUS.length >
                                          20 ? (
                                            <ShortValueField
                                              shortValue={
                                                  item.STATUS.substring(
                                                  0,
                                                  24
                                                ) + " ..."
                                              }
                                              fullValue={
                                                  item.STATUS
                                              }
                                            />
                                          ) : (
                                            item.STATUS
                                          )}
                              </div>
                          </div>
                          )
                      })
                  }                   
                  </div>
                  </BrowserView>
                  <MobileView>
                    <div>
                    {
                      arr.map((item) => {
                          return (
                          <div className="row FirstSubSectionAppTrackDataRow">
                              <div className="FirstSubSectionData col">{item.CHECKINDATE}</div>
                              <div className="FirstSubSectionData col2">
                                  {item.UNIT != null && item.UNIT.length >
                                          14 ? (
                                            <ShortValueField
                                              shortValue={
                                                  item.UNIT.substring(
                                                  0,
                                                  10
                                                ) + " ..."
                                              }
                                              fullValue={
                                                  item.UNIT
                                              }
                                            />
                                          ) : (
                                            item.UNIT
                                          )}
                              </div>
                              <div className="FirstSubSectionData col2">
                              {item.STATUS != null && item.STATUS.length >
                                          14 ? (
                                            <ShortValueField
                                              shortValue={
                                                  item.STATUS.substring(
                                                  0,
                                                  10
                                                ) + " ..."
                                              }
                                              fullValue={
                                                  item.STATUS
                                              }
                                            />
                                          ) : (
                                            item.STATUS
                                          )}
                              </div>
                          </div>
                          )
                      })
                    }                   
                    </div>
                  </MobileView>
              </div>
                  ): (
                    <div></div>
                  )
              }
              
            </React.Fragment>);
            }
}
export default AppTrackGetStatus;
