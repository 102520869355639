import React, {
  useContext,
  useState
} from "react";
import { Link } from "react-router-dom";
import SideMenuContext from "../../context/sideMenuContext";
import { loadImage } from "../../services/uiService";

import "../../styles/sidebar.css";

import "../../styles/home.css";
//import { render } from "@testing-library/react";
//import { isMobile } from "react-device-detect";

const SideBar = (props) => {
  const sideMenuContext = useContext(SideMenuContext);
  const menues = sideMenuContext.menues;
  //const images = require.context("../images/", true);

 
  const [menuOpen, setMenuOpen] = useState(false);

  
  //const functionsSet = new Set();
  /*
  function setMenuOpen1 ()
    {
      setMenuOpen(!menuOpen);
      
      console.log("menuOpen=",menuOpen);
      //alert(" Click onItem");
      
      //nv.collapse('hide') ;
      //.collapse('hide');
     
    }*/

  // const loadImage = useCallback((imgName) => {
  //   let imgsrc = images(`./${imgName}`);
  //   return imgsrc;
  // });
  // functionsSet.add(loadImage);

  function handleClickAnimation(event){
   
    sessionStorage.setItem('CurrentMouseLocation',event.pageX + "," + event.pageY);

    var sideMenuDiv = document.getElementById('SideMenu');
    
    if(sideMenuDiv !== undefined)
    {
      console.log('sideMenuDiv !== undefined');

      sideMenuDiv.style.animationName = "pageAnimationSideBarMobile";
    }

    //collapseMenu();
  }
  /*
  function collapseMenu()
  {
  
      const nv = document.getElementsByClassName("navbar-collapse");
      console.log("Collapse nv=" ,nv);
      console.log("Collapse nv 1=" ,nv.collapse);
     // console.log("Collapse");
     // if (nv !== undefined){ console.log("Collapse");
       nv.collapse = "hide";
  //}
  
  
  }*/

  function onMouseOver(event){
    if(event.target.tagName === 'LI')
    {
      var selectAttr = event.target.getAttribute('select');
      if(selectAttr === 'false')
      {
        event.target.className = event.target.id;
      }
    }
    else{
      var selectAttr = event.target.parentElement.parentElement.getAttribute('select');
      if(selectAttr === 'false')
      {
        event.target.parentElement.parentElement.className = event.target.parentElement.parentElement.id;
      }
    }
  }

  function onMouseOut(event){
    if(event.target.tagName === 'LI')
    {
      var selectAttr = event.target.getAttribute('select');
      if(selectAttr === 'false')
      {
        event.target.className = 'SideMenuLink';
      }
    }
    else{
      var selectAttr = event.target.parentElement.parentElement.getAttribute('select');
      if(selectAttr === 'false')
      {
        event.target.parentElement.parentElement.className = 'SideMenuLink';
      }
    }
  }

  function closeHelp() {
    var sideMenuDiv = document.getElementById('SideMenu');

    if(sideMenuDiv !== undefined)
    {
      console.log('sideMenuDiv !== undefined');

      sideMenuDiv.style.animationName = "pageAnimationSideBarMobileReverse";
      sideMenuDiv.style.animationDuration = "0.75s";

      //sideMenuDiv.style.animationName = "pageAnimationSideBarMobile";
    }
  }

  return (
    // <ul id="SideMenu">
    //   <li className="SideMenuLink" key="Home">
    //     <Link className="nav-link" to={mItem.to} onClick={props.handleClick}>
    //       <img src={mItem.img} alt={friend.title} /> {mItem.Title}
    //     </Link>
    //   </li>
    //   ))}
    // </ul>
    <React.Fragment>
      <nav id="leftMenu" className="navbar    navbar-expand-lg navbar-light">
      <div className="navbar-collapse collapse" id="mainMenuNav">
      
      <ul id="SideMenu" className="navbar-nav-vertical mr-auto"  aria-expanded="false">
        
    
        {menues.map((mItem) => (
          <div key={mItem.id}    >
            {mItem.beforeSpace && (
              <div className={mItem.beforeSpaceClass}>
                {mItem.beforeTitle && (
                  <p className={mItem.beforeTitleClass}>{mItem.beforeTitle}</p>
                )}
              </div>
            )}
             <Link to={{pathname: mItem.to ,state:'flushDeal'}} key={mItem.id} onClick={()=> { props.handleClick(mItem);}}  >
                <li  id={mItem.activeClassName} select={props.currentLocation === mItem.to ? 'true' : 'false'}
                  className={
                    props.currentLocation === mItem.to
                      ? mItem.activeClassName
                      : mItem.regClassName
                  }
                  key={mItem.id} onClick={handleClickAnimation} onMouseOver={onMouseOver} onMouseOut={onMouseOut}
               
               >
              
             
              
           
                {/* <a className="nav-link nav-link-sm  collapsed" href={ mItem.to} key={mItem.id} onClick={props.handleClick(mItem)} > */}
                  <span className="sideMenuImg"    >
                    {props.currentLocation === mItem.to ? (
                      <img src={loadImage(mItem.activeImg)} alt={mItem.Title} />
                    ) : (
                      <img
                        src={loadImage(mItem.nonActiveImg)}
                        alt={mItem.Title}
                      />
                    )}
                  </span>
                  <span>
                    <span  
                      className={
                        props.currentLocation === mItem.to
                          ? mItem.activeTitleClassName
                          : mItem.regTitleClassName
                      }
                    >
                    {mItem.Title}
                    </span>
                  </span>
                  {/* </a> */}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                </li>
                </Link>      
          </div>
        ))}
      </ul>
      
      </div>
     
      </nav>
      
    </React.Fragment>
  );
};

export default SideBar;
