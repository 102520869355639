import React, { Component } from "react";
import ShortValueField from "../fields/shortValueField";
import {BrowserView, MobileView} from 'react-device-detect';
// import "../../styles/landregistry.css";

class SearchLandResultsForm extends Component {
  state = {
    searchResult: this.props.searchResult,
    additionSearchResults: this.props.additionSearchResults,
    updateShowCase: this.props.updateShowCase,
    nextStage: this.props.nextStage,
    errorStage: this.props.errorStage,
    deliveryType: "Digital print",
    paymentMethod: "Credit card",
    propertyName: "",
    backToPrevStage: this.props.backToPrevStage,
    prevStage: this.props.prevStage,
    landid: null,
    land_no: this.props.land_no,
    block_no: this.props.block_no
  };

  constructor(props) {
    super(props);
    this.confirmData = this.confirmData.bind(this);
  }

  confirmData = () => {
    const { updateShowCase, nextStage } = this.state;
    updateShowCase(nextStage);
  };

  backStage = () => {
    const { backToPrevStage, prevStage, land_no, block_no } = this.state;
    backToPrevStage(prevStage, land_no, block_no);
  };

  render() {
    const {
      searchResult,
      additionSearchResults,
      deliveryType,
      paymentMethod,
    } = this.state;

    let propertyName = "";
    let land_no = "";
    let block_no = "";
    let landid = "";
    let feeValue = "";
    let searchJSON = "";
    let additionSearchJSON = "";
    let propertyNameShort = "";
    if (searchResult !== undefined) {
      searchJSON = JSON.parse(searchResult);
      if (searchJSON[0] !== undefined && searchJSON[0].LAND_NO !== undefined)
      land_no = searchJSON[0].LAND_NO;
      if (searchJSON[0] !== undefined && searchJSON[0].LAND_ID !== undefined)
        landid = searchJSON[0].LAND_ID;
    }
    if (additionSearchResults) {
      additionSearchJSON = JSON.parse(additionSearchResults);

      if (
        additionSearchJSON !== undefined &&
        additionSearchJSON.Table1 !== undefined &&
        additionSearchJSON.Table1[0] !== undefined
      )
      {
        if (additionSearchJSON.Table1[0].LAND_NO !== undefined)
        land_no = additionSearchJSON.Table1[0].LAND_NO;

        if (additionSearchJSON.Table1[0].LAND_ID !== undefined)
          landid = additionSearchJSON.Table1[0].LAND_ID;
      }
      if (
        additionSearchJSON !== undefined &&
        additionSearchJSON.Table2 !== undefined &&
        additionSearchJSON.Table2[0] !== undefined &&
        additionSearchJSON.Table2[0].VALUE !== undefined
      )
        feeValue = additionSearchJSON.Table2[0].VALUE.toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
/*
    if (propertyName.length > 11) {
      propertyNameShort = propertyName.substring(0, 9) + " ...";
    }
*/
    return (
      <React.Fragment>
        <div id="SearchLandResultsTitle">
          Property was found. Please confirm the payment amount and method and click “Next” to proceed.
        </div>
        <MobileView>
          <div className="SearchLandResults">
            {/* <div className="ResultRow">
              <div className="ColumnTitle1">Property No.</div>
              <div className="SearchResultItem">
              {propertyName.length > 11 ? (
                <ShortValueField
                  shortValue={propertyNameShort}
                  fullValue={propertyName}
                />
              ) : (
                <div>{propertyName}</div>
              )}
              </div>
            </div> */}
            <div className="ResultRow">
              <div className="ColumnTitle1">Delivery</div>
              <div className="SearchResultItem1">{deliveryType}</div>
            </div>
            <div className="ResultRow">
              <div className="ColumnTitle1">Payment</div>
              <div className="SearchResultItem1">{paymentMethod}</div>
            </div>
            <div className="ResultRow">
              <div className="ColumnTitle1">Cost (₦)</div>
              <div className="SearchResultItem1">{feeValue}</div>
            </div>
          </div>
        </MobileView>
        <BrowserView>
          <div className="SearchLandResults">
            <div id="SearchResultTitles">
              {/* <div className="ColumnTitle">Property No.</div> */}
              <div className="ColumnTitle1">Delivery</div>
              <div className="ColumnTitle1">Payment</div>
              <div className="ColumnTitle1">Cost (₦)</div>
            </div>
            <div id="SearchResultData">
              {/* <div className="SearchResultItem">
                {propertyName.length > 11 ? (
                  <ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={propertyName}
                  />
                ) : (
                  <div>{propertyName}</div>
                )}
              </div> */}
              <div className="SearchResultItem1">{deliveryType}</div>
              <div className="SearchResultItem1">{paymentMethod}</div>
              <div className="SearchResultItem1">{feeValue}</div>
            </div>
            {/* <div> */}

            {/* </div> */}
          </div>
        </BrowserView>
        
        <MobileView>
          <div className="confirmButtonsDiv">
            <button
              className="btnConfirm"
              type="submit"
              autoFocus
              onClick={this.confirmData}
            >
              Next
            </button>
            <button className="btnCancel" onClick={this.backStage}>
              Back
            </button>
          </div>
        </MobileView>
        <BrowserView>
          <div className="confirmButtonsDiv">
              <button className="btnCancel" onClick={this.backStage}>
                Back
              </button>
              <button
                className="btnConfirm"
                type="submit"
                autoFocus
                onClick={this.confirmData}
              >
                Next
              </button>
          </div>
        </BrowserView>
        
      </React.Fragment>
    );
  }
}

export default SearchLandResultsForm;
