import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {BrowserView} from 'react-device-detect';
import Form from "./form";
import { logintk, verifytk } from "../../services/authService";
import PhoneInput from "react-phone-number-input/mobile";

// import { showProgressBar } from "../../services/uiService";
import {
  showProgressBar,
  getErrorMessages,
  hideProgressBar,
} from "../../services/uiService";

//import Input from "../fields/input";

import Joi from "joi-browser";

//import Input from "../fields/input";
//import Select from "../fields/select";
//import VerificationPopup from "./verificationPopup";
//import PersonalData from "./personalData";

class OneTimeLoginForm extends Form {
  state = {
    data: {
      NationalIdOrFullName: "",
      CellularOrEmail: "",
      UserPhoneNumber:"",
      IdentityUserBy: "",
      TokenTargetType: "",
      //  userName: "", password: ""
    },
    IdentityUserByOptions: [
      { val: "1", display: "National Id", selected: "selected" },
      { val: "2", display: "Full name", selected: "" },
    ],
    TokenTargetTypeOptions: [
      { val: "1", display: "Mobile", selected: "selected" },
      { val: "2", display: "E-mail", selected: "" },
    ],
    errors: {},
    popupSeen: false,
    verificationCode: "",
    confirmationMessage: "",
    userVerifyCode: "",
    tmpSessionId: "",
    closeLoginForm: this.props.closeLoginForm,
    setVerificationCode: this.props.setVerificationCode,
    resendCode: this.props.resendCode,
    error: "",
    userError: {},
    tokenError: "",
  };

  schema = {
    NationalIdOrFullName: Joi.string()
      .required()
      .label("National ID or Full Name"),
    CellularOrEmail: Joi.string()
      .required()
      //.regex(/^([0-9]{3})?[-\s]?[0-9]{1}?[-\s]?[0-9]{6}$/)
      // .regex(/^([0-9]{3})?[-\s]?[-\s]?[0-9]{7}$/)
      .label("Mobile or E-mail"),
    IdentityUserBy: Joi.string().required().label("Identify User by"),
    TokenTargetType: Joi.string().required().label("Send Token by"),
  };

  // handelSubmit = (e) => {
  //   e.preventDefaults();
  //   console.log("Submited");
  // };

  componentDidMount() {
    let { data } = this.state;
    const IdentityUserByOptions = [...this.state.IdentityUserByOptions];
    //console.log(IdentityUserByOptions);
    const TokenTargetTypeOptions = [...this.state.TokenTargetTypeOptions];
    data.IdentityUserBy = IdentityUserByOptions[0].val;
    data.TokenTargetType = TokenTargetTypeOptions[0].val;
    //   (opt) => {opt.selected = "selected").val
    // );
    // data.TokenTargetType = TokenTargetTypeOptions.filter(
    //   (opt) => (opt.selected = "selected")
    // );
    this.setState({ data: data });
    // console.log(this.state.data);

    this.showContentAfterAnimation();
  }

  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  checkEmailOrMobile = (e) => {
    e.preventDefault();

    let { data, errorMessage } = this.state;
    errorMessage = "";
    let errCase = 0;

    if (data.NationalIdOrFullName.length === 0) {
      if (data.IdentityUserBy === 1) errorMessage = "Please enter National Id.";
      else errorMessage = "Please enter Full Name.";
      errCase = 1;
    } else {
      if (data.TokenTargetType === 1) {

        let ddlMobileOrEmail = document.getElementById('TokenTargetType');
        let ddlMobileOrEmailValue = ddlMobileOrEmail.options[ddlMobileOrEmail.selectedIndex].value;

        if(ddlMobileOrEmailValue === '1')
        {
          if (data.UserPhoneNumber.length === 0) {
            errorMessage = "Please enter your mobile number";
          }
        }
       
        //  var phoneRegex = /^([0-9]{3})?[-\s]?[0-9]{1}?[-\s]?[0-9]{6}$/;
        //        var phoneRegex = /^([0-9]{3})?[-\s]?[-\s]?[0-9]{7}$/; // Change this regex based on requirement
        var phoneRegex = /^(?=.*?[0-9])[0-9()+-\s]+$/;

        

        if(ddlMobileOrEmailValue === '1')
        {
          if (!phoneRegex.test(data.UserPhoneNumber)) {
            errorMessage = "Please enter a valid mobile number.";
          }
        }
        
        //.regex(/^([0-9]{3})?[-\s]?[0-9]{1}?[-\s]?[0-9]{6}$/)
        // .regex(/^([0-9]{3})?[-\s]?[-\s]?[0-9]{7}$/)
        errCase = 2;
      } else {
        var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (data.CellularOrEmail.length === 0) {
          errorMessage = "Please enter your E-mail address";
        } else if (!mailFormat.test(data.CellularOrEmail)) {
          errorMessage = "Please enter a valid E-mail address";
        }
        errCase = 2;
      }
    }

    if (errorMessage !== undefined && errorMessage.length > 0) {
      this.setState({ errorMessage });
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";

        if (errCase === 1) {
          document.getElementById("NationalIdOrFullName").className +=
            " ErrorInputFieldStyle";
        } else if (errCase === 2) {
          document.getElementById("CellularOrEmail").className +=
            " ErrorInputFieldStyle";
        }
        /*
        
        if (data.Email.length === 0) {
      errorMessage = "Please enter your E-mail address.";
      this.showError(errorMessage);
      document.getElementById("Email").className += " ErrorInputFieldStyle";
      return;
    } else if (!mailFormat.test(data.Email)) {
      errorMessage = "Please enter a valid E-mail address.";
      document.getElementById("Email").className += " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("Email").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("Email").className = classname;
    }
        */
      }
    } else {
      let classname = document.getElementById("NationalIdOrFullName").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("NationalIdOrFullName").className = classname;

      classname = document.getElementById("CellularOrEmail").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("CellularOrEmail").className = classname;

      var errorMessageDiv = document.getElementById("ErrorMessageDiv");

      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "hidden";
      }
      this.setState({ errors: {}, errorMessage: "" });
      this.doSubmit();
    }
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;

      let ddlMobileOrEmail = document.getElementById('TokenTargetType');
      let ddlMobileOrEmailValue = ddlMobileOrEmail.options[ddlMobileOrEmail.selectedIndex].value;
      let mobilePhoneNumber = '+';

      if(ddlMobileOrEmailValue === '1')
      {
        mobilePhoneNumber = data.UserPhoneNumber;
        data.CellularOrEmail = mobilePhoneNumber;
      }
      
      data.TokenTargetType = ddlMobileOrEmailValue;

      showProgressBar();
      /**
      *  token = tokenString,
                        verificationcode = verificationCode,
                        tmpSessionId = tmpSession.Id.ToString(),
                        expiration = DateTime.Now.AddMinutes(30),
                        status = "OK",
                        message = sendVerificationResult.ResultMessage
      */
      const {
        data: token,
        verificationcode,
        tmpSessionId,
        exparation,
        status,
        message,
      } = await logintk(
        data.NationalIdOrFullName,
        data.CellularOrEmail,
        data.IdentityUserBy,
        data.TokenTargetType
      );

      console.log("Submited result", token);
      console.log("Submited result 1 ", token["token"]);
      console.log("Submited result 2", token["expiration"]);
      console.log("Submited result 3", token["verificationcode"]);
      console.log("Submited result 4", token["status"]);
      console.log("Submited result 5", token["message"]);
      console.log("Submited result 6", token["message"]);

      //   localStorage.setItem("token", token["token"]);
      //   localStorage.setItem("tokenExparation", token["expiration"]);
      if (token["status"] === "OK") {
        // console.log("before popunSeen");
        this.setState({
          verificationCode: token["verificationcode"],
          confirmationMessage: token["message"],
          tmpSessionId: token["tmpSessionId"],
          // popupSeen: !this.state.popupSeen,
        });

        //   this.setState({ tokenError: "" }, { error: "" }, { errors: {} });
        this.setState({ tokenError: "" });
        this.props.setVerificationCode(
          this.state.data.NationalIdOrFullName,
          this.state.data.CellularOrEmail,
          this.state.data.IdentityUserBy,
          this.state.data.TokenTargetType,
          this.state.verificationCode,
          this.state.confirmationMessage,
          this.state.tmpSessionId
        );
        
        //        this.togglePopup.bind(this);

        //      console.log("after popunSeen");
      } else {
        let errors = { ...this.state.errors };
        errors.CellularOrEmai = token["message"];

        if (token["status"] === "SEND_ERROR") {
          if (
            token["message"] ===
            "Error while parsing XML: Message must contain at least one valid destination"
          ) {
            let error = "Please enter correct mobile number";
            //var phoneNumberTxt = document.getElementById("CellularOrEmail");
            //phoneNumberTxt.style.border = "1px solid red";
            // var errorDiv = phoneNumberTxt.parentElement.children[1];
            // if (errorDiv !== undefined) {
            //  errorDiv.style.visibility = "visible";
            // }
            this.setState({ errorMessage: error });
          } else {
            const tokenError = token["message"];
            this.setState({ errorMessage: tokenError });
          }
        } else if (token["status"] === "USER_ERROR") {
          const userError = getErrorMessages(token["message"]);
          this.setState({ userError: userError });
          // console.log("ERROR MSG=", this.state.userError.Message);
          // console.log("ERROR LINK", this.state.userError.LinkTo);
        } else {
          // errors["CellularOrEmai"] = token["message"];
          const tokenError = token["message"];
          this.setState({ errorMessage: tokenError });
        }
        console.log(this.state.errors);
      }

      //console.log("Submited result", token);
      //   console.log("Submited result 1 ", token["token"]);
      //   console.log("Submited result 2", token["expiration"]);
      //   localStorage.setItem("token", token["token"]);
      //   localStorage.setItem("tokenExparation", token["expiration"]);
      //   console.log("Submited");
      //   window.location = "/";
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.NationalIdOrFullName = ex.response.data;
        this.setState({ errors });
        console.log(errors);
      }
    }
    hideProgressBar();
  };

  togglePop = () => {
    // this.props.closeLoginForm();
    this.setState({
      popupSeen: !this.state.popupSeen,
    });
  };

  getPopData = (verCode) => {
    // console.log("VERIFICATION CODE FROM POP", verCode);
    const userVerifyCode = verCode;
    this.state.userVerifyCode = verCode;
    // console.log("VERIFICATION CODE FROM POP 1=", userVerifyCode);

    console.log("VERIFICATION CODE FROM POP 2=", this.state);
    this.togglePop();
    this.verifyUser();
  };

  closeWin = (e) => {
    this.state.closeLoginForm();
  };

  verifyUser = async () => {
    console.log("getPopData");

    const { data, verificationCode, userVerifyCode, tmpSessionId } = this.state;
    console.log("getPopData=", verificationCode);
    console.log("getPopData=", userVerifyCode);
    console.log("getPopData=", this.state.userVerifyCode);
    console.log("getPopData=", tmpSessionId);
    /*verificationCode: "",
    confirmationMessage: "",
    tokenError: "",
    userVerifyCode: "",
    tmpSessionId:""*/

    if (verificationCode === userVerifyCode) {
      try {
        const { data: token, exparation } = await verifytk(
          data.NationalIdOrFullName,
          data.CellularOrEmail,
          data.IdentityUserBy,
          data.TokenTargetType,
          userVerifyCode,
          tmpSessionId
        );
        sessionStorage.setItem("token", token["token"]);
        sessionStorage.setItem("tokenExparation", token["expiration"]);

        var dateNow = new Date(Date.now());
        var dateExpire = new Date(token["expiration"]);

        var timeoutMinutes = Math.round(
          (dateExpire.getTime() - dateNow.getTime()) / 1000 / 60
        );

        console.log("Time left:" + timeoutMinutes + " minutes");

        sessionStorage.setItem("TimeoutInSeconds", timeoutMinutes * 60);

        console.log("Submited");
        window.location = "/home";
      } catch (ex) {
        console.log("Verification code error", ex);
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.NationalIdOrFullName = ex.response.data;
          this.setState({ errors: errors });
          console.log(errors);
        }
      }
    }
  };

  // componentDidMount() {
  //   this.userName.current.focus();
  // }

  // handleChange = ({ currentTarget: input }) => {
  //   const account = { ...this.state.account };
  //   account[input.name] = input.value;
  //   this.setState({ account });
  // };

  onChangeEvent = () => 
  {
    let e = document.getElementById('TokenTargetType');
    let ddlValue = e.options[e.selectedIndex].value;
    console.log('onChangeEvent: ' + e.options[e.selectedIndex].value);
    if(ddlValue === "1")
    {
      document.getElementById('CellularOrEmailDiv').style.display='none';
      document.getElementById('CellularDiv').style.display='block';
    }
    if(ddlValue === "2")
    {
      document.getElementById('CellularOrEmailDiv').style.display='block';
      document.getElementById('CellularDiv').style.display='none';
    }
  };

  changeMobileNumber = (value) => {
    //alert(value);
    //console.log(e.target.value);
    let { data } = this.state;
    if (value !== undefined) {
      data.UserPhoneNumber = value;
    } else {
      data.UserPhoneNumber = "+";
    }

    this.setState({ data: data });
    //alert("mobiLe=" + this.state.data.mobile + "=");
  };

  onClickPhoneInput = (value) =>{
    let mobileInput = document.getElementById('mobileInput');
    if(mobileInput !== undefined)
    {
      mobileInput.value = '+';
    }
  }

  render() {
    const { data, errorMessage } = this.state;

    // const tokenError = this.state.tokenError;
    //
    // const error = this.state.error;
    const errors = { ...this.state };
    const userError = { ...this.state.userError };
    //console.log("RENDER DATA=", data);
    //console.log("RENDER errors=", errors);
    //console.log("RENDER userError=", userError);
    if (userError !== undefined || errorMessage !== undefined) {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    }

    const IdentityUserByOptions = [...this.state.IdentityUserByOptions];
    console.log(IdentityUserByOptions);
    const TokenTargetTypeOptions = [...this.state.TokenTargetTypeOptions];
    console.log(TokenTargetTypeOptions);

    return (
      <React.Fragment>
        <BrowserView>
          <div id="smallCloseBtnDivOneTimeLogin"
            className="smallCloseBtnDivOneTimeLogin"
            onClick={() => {
              document.getElementById("Cancel_NotSubmitBtn").click();
            }}
          >
            <button id="closeBtn"
              className="closeBtn"
              onClick={() => {
                document.getElementById("Cancel_NotSubmitBtn").click();
              }}
            ></button>
          </div>
        </BrowserView>

        <div className="LogInTitle">Log in to your account</div>
        <div id="LoginContent">
          <div className="LogInTitle2">
            You personal area for online services
          </div>
          <div className="OneTimeTokenTitle">With one-time token</div>
          <div className="LoginForm">
            <form onSubmit={this.handleSubmit} className="formLogin">
              <div id="ErrorMessageDiv" className="ErrorMessageDivLogIn ">
                {errorMessage !== undefined && errorMessage.length > 0 && (
                  <span>
                    <span className="ErrorMessageExclamationMark">!</span>
                    {errorMessage}
                  </span>
                )}
                {userError !== undefined &&
                  userError.Title !== undefined &&
                  userError.Title.length > 0 && <p>{userError.Title}</p>}
                {userError !== undefined &&
                  userError.Message !== undefined &&
                  userError.Message.length > 0 && (
                    <span>
                      <span className="ErrorMessageExclamationMark">!</span>
                      {userError.Message}
                    </span>
                  )}

                {userError !== undefined &&
                  userError.LinkTo !== undefined &&
                  !userError.IsLinkSeparate && (
                    <span className="LoginWithPasswordLinkSpan">
                      {"    "}
                      <NavLink
                        to={userError.LinkTo}
                        className="LoginWithPasswordLink1"
                      >
                        <span>{userError.LinkTitle}</span>
                      </NavLink>
                    </span>
                  )}
                {userError.LinkTo && userError.IsLinkSeparate && (
                  <span className="LoginWithPasswordLinkSpan">
                    <NavLink to={userError.LinkTo}>
                      <span>{userError.LinkTitle}</span>
                    </NavLink>
                  </span>
                )}
              </div>

              <div className="row">
                {this.renderSelect(
                  "IdentityUserBy",
                  "",
                  IdentityUserByOptions,

                  "colOTLoginSelect",
                  "",
                  "",
                  "true"
                )}

                {
                  this.renderInput(
                  "NationalIdOrFullName",
                  "",
                  "colOTLoginInput",
                  "text",
                  "Identification",
                  "",
                  "",
                  "true"
                )}
              </div>
              <div className="row">
                {
                  /*this.renderSelect(
                  "TokenTargetType",
                  "",
                  TokenTargetTypeOptions,
                  "colOTLoginSelect",
                  "",
                  "",
                  ""
                  )*/
                  
                }
                <div className="colOTLoginSelect">
                  <select id="TokenTargetType" name="TokenTargetType" className="colOTLoginSelect" onChange={this.onChangeEvent}>
                    <option value="1">Mobile</option>
                    <option value="2">E-mail</option>
                  </select>
                </div>
                <div className="colOTLoginInput">
                  <div style={{display:"none"}} id="CellularOrEmailDiv">
                    {this.renderInput(
                      "CellularOrEmail",
                      "",
                      "",
                      "email",
                      "Via",
                      "",
                      "",
                      ""
                    )}
                  </div>
                  <div className="colOTLoginPhoneInput w20" id="CellularDiv">
                    <PhoneInput
                        className="col-15"
                        placeholder="Mobile"
                        international
                        id="mobileInput"
                        // defaultCountry="ZM"
                        value={data.UserPhoneNumber}
                        onChange={this.changeMobileNumber}
                        onClick={this.onClickPhoneInput}
                      />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="FormButtonsDivLogin">
                    {this.renderNotSubmitButton(
                      "Cancel",
                      "btnCancel",
                      this.props.closeLoginForm
                    )}
                  
                    <button
                      className="btnConfirm"
                      onClick={this.checkEmailOrMobile}
                    >
                      Send token
                    </button>
                  </div>
                </div>
              </div>
             
            </form>
          </div>{" "}
          <div className="LoginWithPasswordTitle LoginWithPasswordTitleMargin">
            Click
            <NavLink className="LoginWithPasswordLink" to="/home/login/2">
              <span> here </span>
            </NavLink>
            to sign in with your password
          </div>
          <div className="LoginWithPasswordTitle RegisterTitleMargin">
            Not yet registered?{" "}
            <NavLink className="LoginWithPasswordLink" to="/home/register">
              <span> Join now </span>
            </NavLink>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OneTimeLoginForm;
