import React, { Component } from "react";
//import Joi from "joi-browser";
import {isBrowser,isMobile} from 'react-device-detect';

//import Form from "./form";
import UserContext from "./../../context/userContext";
import { search } from "../../services/searchService";

//import { updateUser } from "../../services/userService";
//import { getSessionUser } from "../../services/sessionServices";

import { getTitleMessages } from "../../services/uiService";
import {
  loadImage,
  showProgressBar,
  hideProgressBar,
} from "../../services/uiService";
//import { NavLink, Link } from "react-router-dom";
import {
  getLandRegistarReport,
  //getPaymentDNReport,
  getlandRegistarReceipt,
} from "../../services/reportService";

class LandsRegisterHistory extends Component {
  static contextType = UserContext;
  state = {
    data: {
      userID: this.context ? this.context.UID : "0",
      // ownerId: this.context ? this.context.ownerId : "0",
    },
    paramsData: [{ FieldName: "userID", FieldType: "Number" }],
    additionalSearch: [{ SearchKey: "OWNER_LANDS_REGISTER_HISTORY" }],

    errors: {},
    tokenError: "",
    toggleSection: false,
    toggleCurrentClass: "CloseSectionDiv",
    toggleSubSection: false,
    toggleSubSectionCurrentClass: "CloseSubSectionDiv",
  };

  schema = {};

  componentDidMount() {}

  changeToggle = async () => {
    const landsRegisterHistory = this.context.landsRegisterHistory;
    if (
      landsRegisterHistory !== undefined &&
      landsRegisterHistory != null &&
      landsRegisterHistory.length > 0
    ) {
      console.log("Not Undefined or Not Null");
    } else {
      console.log("Undefined or Null");
      await this.getData();
    }

    //const toggle = !this.state.toggleSection;
    // const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";

    const toggle = !this.context.landsRegisterHistoryInitialToggle;
    this.context.landsRegisterHistoryInitialToggle = toggle;

    const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";
    this.context.landsRegisterHistoryToggleClass = toggle
      ? "OpenSectionDiv"
      : "CloseSectionDiv";
    if(isBrowser){
      var workAreaDiv = document.getElementById("workArea");
      if (workAreaDiv !== undefined) {
        var myProps = document.getElementById("LandsRegisterHistoryDiv");
        if (myProps !== undefined) {
          if (className === "OpenSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              workAreaDiv.offsetHeight + myProps.offsetHeight * 16 + "px";
          }
          if (className === "CloseSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              workAreaDiv.offsetHeight - myProps.offsetHeight * 16 + "px";
          }
        }
      }
    }
    

    this.setState({
      toggleSection: this.context.landsRegisterHistoryInitialToggle,
      toggleCurrentClass: this.context.landsRegisterHistoryToggleClass,
    });
  };

  getData = async () => {
    const data = { ...this.state.data };

    console.log(data);

    const { paramsData, additionalSearch } = this.state;

    //if (ownerID != "0") {
    console.log("GET_DATA =", data);
    console.log("GET_DATA =", paramsData);
    console.log("GET_DATA =", additionalSearch);
    try {
      const SearchResponse = await search(
        data,
        "",
        paramsData,
        additionalSearch
      );
      console.log("Land register GET_DATA =", SearchResponse);

      const searchResult = SearchResponse.data["searchResult"];
      const additionSearch = SearchResponse.data["additionSearch"];
      const searchStatus = SearchResponse.data["searchStatus"];
      const message = SearchResponse.data["message"];

      if (searchStatus === "OK") {
        let additionSearchJSON = "";
        if (additionSearch) {
          additionSearchJSON = JSON.parse(additionSearch);
          if (typeof additionSearchJSON.Table1 !== undefined) {
            this.context.landsRegisterHistory = additionSearchJSON.Table1;
            //this.context.handleLandsRegisterHistory(additionSearchJSON.Table1);
          }
        } else {
          // this.handleLandsRegisterHistory([]);
        }

        console.log(
          "GET_DATA this.context.landsRegisterHistory=",
          this.context.landsRegisterHistory
        );
        // console.log(
        //   "GET_DATA this.context.ownerDemandNotices=",
        //   this.context.landsRegisterPayments
        // );
      }
    } catch (ex) {
      console.log("Land Registry History Error", ex);
    }
  };

  render() {
    let landsregisterHistory = [];
    const documentImage = loadImage("Document.svg");

    const UserId = this.context.UserId;
    const OwnerId = this.context.OwnerId;
    const isZilmisUser = OwnerId > 0 ? 1 : 0;
    //const paymentImage = loadImage("ic-ecommerce-card.svg");

    if (
      typeof this.context.landsRegisterHistory !== "undefined" ||
      this.context.landsRegisterHistory != null
    ) {
      landsregisterHistory = this.context.landsRegisterHistory;
      console.log("landsRegisterHistory 1=", this.context.landsRegisterHistory);
      console.log("ownerProperties 2=", landsregisterHistory);
    }

    console.log("this.state.toggleSection=", this.state.toggleSection);

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <React.Fragment>
            <div className="SectionDiv" onClick={this.changeToggle}>
              <div
                className={this.context.landsRegisterHistoryToggleClass}
              ></div>
              <div className="SectionTitle">Lands Register Printouts</div>
            </div>
            <div>
              { isBrowser && this.context.landsRegisterHistoryInitialToggle &&
              landsregisterHistory.length > 0 ? (
                <div className="d-block">
                  <div className="FirstSubSectionTitleRow row">
                    <div className="FirstSubSectionTitle col">Property</div>
                    <div className="FirstSubSectionTitle col">Delivery</div>
                    <div className="FirstSubSectionTitle col">
                      Purchase date
                    </div>
                    <div className="FirstSubSectionTitle col">
                      Download printout
                    </div>
                    <div className="FirstSubSectionTitle col">
                      Download receipt
                    </div>
                  </div>

                  {landsregisterHistory.map((item) => {
                    return (
                      <div>
                        <div className="row FirstSubSectionDataRow">
                          <div className="FirstSubSectionData col">
                            {item.LAND_NO}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.DELIVERY}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.ACTION_DATE}
                          </div>
                          <div className="FirstSubSectionData col">
                            <a target="_blank">
                              <span className="documentImageSpan">
                                <img
                                  src={documentImage}
                                  className="documentImage"
                                  onClick={async () => {
                                    showProgressBar();

                                    await getLandRegistarReport(item.LAND_NO);

                                    hideProgressBar();
                                  }}
                                />
                              </span>
                            </a>
                          </div>

                          <div className="FirstSubSectionData col">
                            <a target="_blank">
                              <span className="documentImageSpan">
                                <img
                                  src={documentImage}
                                  className="documentImage"
                                  onClick={async () => {
                                    showProgressBar();
                                    await getlandRegistarReceipt(
                                      isZilmisUser,
                                      item.ZILMIS_RC_ID,
                                      " "
                                    );
                                    hideProgressBar();
                                  }}
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : isBrowser && this.context.landsRegisterHistoryInitialToggle &&
                landsregisterHistory.length === 0 ? (
                <div className="NoDataMessage">
                  {
                    getTitleMessages("NO_LAND_REGISTER_PRINTOUT_MESSAGE")[
                      "Message"
                    ]
                  }
                </div>
              ) : (
                ""
              )}
              {
                isMobile && this.context.landsRegisterHistoryInitialToggle &&
                landsregisterHistory.length > 0 ? (
                  landsregisterHistory.map((item) => {
                  return (
                    <div style={{borderBottom:"0.01rem solid lightgrey",marginBottom:"0.5rem"}}>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Property</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.LAND_NAME}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Delivery</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.DELIVERY}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Purchase date</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">{item.ACTION_DATE}</div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Download printout</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">
                          <a target="_blank">
                            <span className="documentImageSpan">
                              <img
                                src={documentImage}
                                className="documentImage"
                                onClick={async () => {
                                  showProgressBar();

                                  await getLandRegistarReport(item.LAND_NAME);

                                  hideProgressBar();
                                }}
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="MyPropertiesMobileRow">
                        <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Download receipt</div>
                        <div className="FirstSubSectionData MyPropertiesMobileColData">
                          <a target="_blank">
                            <span className="documentImageSpan">
                              <img
                                src={documentImage}
                                className="documentImage"
                                onClick={async () => {
                                  showProgressBar();
                                  await getlandRegistarReceipt(
                                    isZilmisUser,
                                    item.ZILMIS_RC_ID,
                                    " "
                                  );
                                  hideProgressBar();
                                }}
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                  })
                ) : isMobile && this.context.landsRegisterHistoryInitialToggle &&
                landsregisterHistory.length === 0 ? (
                <div className="NoDataMessage">
                  {
                    getTitleMessages("NO_LAND_REGISTER_PRINTOUT_MESSAGE")[
                      "Message"
                    ]
                  }
                </div>
              ) : (
                ""
              )
              }
            </div>
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

LandsRegisterHistory.contextType = UserContext;
export default LandsRegisterHistory;
