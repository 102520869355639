import http from "./httpService";
import config from "../config.json";
import { showProgressBar } from "../services/uiService";
import { hideProgressBar } from "../services/uiService";
import {isBrowser,isMobile} from 'react-device-detect';

const { usersEndpoint } = config;

export async function forgotPasswordEmail(userName) {
  console.log("FORGOT PWD=", userName + "=");
  var result = null;
  try {
    result = await http.post(usersEndpoint + "/forgotpwd", {
      UserName: userName,
      Password: "xxx",
    });
  } catch (ex) {
    console.log("authService.forgotPasswordEmail error: " + ex);
  }
  return result;
}

export async function resetPassword(uid, sid, pwd) {
  console.log("resetPassword=", uid + "=");
  var result = null;
  try {
    result = await http.post(usersEndpoint + "/resetpwd", {
      UID: uid,
      SID: sid,
      PWD: pwd,
    });
  } catch (ex) {
    console.log("authService.resetPassword error: " + ex);
  }
  return result;
}

export async function logout(sessionID) {
  var result = null;
  try {
    result = await http.get(
      usersEndpoint + "/logout?userSessionID=" + sessionID
    );
    /*result = await http.get(usersEndpoint + "/logout", {
      userSessionID: sessionID,
    });*/
  } catch (ex) {
    console.log("authService.logout error: " + ex);
  }
  return result;
}

export async function login(userName, Password, obj) {
  var result = null;
  try {
    result = await http.post(usersEndpoint + "/login", {
      UserName: userName,
      Password: Password,
    });
  } catch (ex) {
    console.log("authService.login error: " + ex);
  }
  return result;
}

export async function logintk(
  NationalIdOrFullName,
  CellularOrEmail,
  IdentityUserBy,
  TokenTargetType
) {
  //console.log("NationalIdOrFullName=" + NationalIdOrFullName.trim() + "=");
  //console.log("CellularOrEmail=" + CellularOrEmail.trim() + "=");
  //console.log("IdentityUserBy=" + IdentityUserBy + "=");
  //console.log("TokenTargetType=" + TokenTargetType + "=");

  var result = null;
  try {
    result = await http.post(usersEndpoint + "/logintk", {
      NationalIdOrFullName: NationalIdOrFullName.trim(),
      CellularOrEmail: CellularOrEmail.trim(),
      IdentityUserByVal: IdentityUserBy,
      TokenTargetTypeVal: TokenTargetType,
    });
  } catch (ex) {
    console.log("authService.logintk error: " + ex);
  }
  return result;
}

export async function resendtk(
  NationalIdOrFullName,
  CellularOrEmail,
  IdentityUserBy,
  TokenTargetType,
  TmpSessionId
) {
  console.log("NationalIdOrFullName=" + NationalIdOrFullName.trim() + "=");
  console.log("CellularOrEmail=" + CellularOrEmail.trim() + "=");
  console.log("IdentityUserBy=" + IdentityUserBy.trim() + "=");
  console.log("TokenTargetType=" + TokenTargetType.trim() + "=");
  console.log("TmpSessionId=" + TmpSessionId.trim() + "=");

  var result = null;
  try {
    result = await http.post(usersEndpoint + "/resendtk", {
      NationalIdOrFullName: NationalIdOrFullName.trim(),
      CellularOrEmail: CellularOrEmail.trim(),
      IdentityUserByVal: IdentityUserBy.trim(),
      TokenTargetTypeVal: TokenTargetType.trim(),
      TmpSession: TmpSessionId.trim(),
    });
  } catch (ex) {
    console.log("authService.resendtk error: " + ex);
  }
  hideProgressBar();
  return result;
}

export async function verifytk(
  NationalIdOrFullName,
  CellularOrEmail,
  IdentityUserBy,
  TokenTargetType,
  VerificationCode,
  TmpSession
) {
  console.log(
    "verifytk : NationalIdOrFullName=" + NationalIdOrFullName.trim() + "="
  );

  showProgressBar();

  console.log("verifytk : CellularOrEmail=" + CellularOrEmail.trim() + "=");
  console.log("verifytk : IdentityUserBy=" + IdentityUserBy + "=");
  console.log("verifytk : TokenTargetType=" + TokenTargetType + "=");
  console.log("verifytk : VerificationCode=" + VerificationCode + "=");
  console.log("verifytk : TmpSession =" + TmpSession + "=");

  var result = null;
  try {
    result = await http.post(usersEndpoint + "/verifytk", {
      NationalIdOrFullName: NationalIdOrFullName.trim(),
      CellularOrEmail: CellularOrEmail.trim(),
      IdentityUserByVal: IdentityUserBy,
      TokenTargetTypeVal: TokenTargetType,
      VerificationCode: VerificationCode,
      TmpSession: TmpSession,
    });
  } catch (ex) {
    console.log("authService.verifytk error: " + ex);
  }
  hideProgressBar();
  return result;
}
