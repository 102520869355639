import React from 'react';
//import { format, addMonths } from 'date-fns';
import "./../../styles/paymentDetailsForm.css";

const CreditCardDatePicker = ({ selectedMonth, selectedYear, onMonthChange, onYearChange }) => {
  // Generate a list of months (1 to 12)
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  // Generate a list of years (current year to next 15 years)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 15 }, (_, i) => currentYear + i);

  // You can use the selectedMonth and selectedYear values as needed
  /*const formattedExpirationDate = () => {
    if (selectedMonth && selectedYear) {
      const expirationDate = new Date(`${selectedYear}-${selectedMonth-1}-01`);
      return format(addMonths(expirationDate, 1), 'MM/yyyy');
    }
    return '';
  };*/

  return (
    <div>
    <div className="row">
      <select id="ExpDateMM" onChange={onMonthChange} value={selectedMonth}>
        <option value="">Month</option>
        {months.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
      <label>/</label>
      <select id="ExpDateYY" onChange={onYearChange} value={selectedYear}>
        <option value="">Year</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      </div>

      {/* <div>
        <p>Formatted Expiration Date: {formattedExpirationDate()}</p>
      </div> */}
    </div>
  );
};

export default CreditCardDatePicker;
