import React from "react";
import Joi from "joi-browser";
import Form from "./form";
import { search } from "../../services/searchService";

class AppTrackLocateApp extends Form {
  state = {
    ModuleName: "",
    additionalSearch: [{ SearchKey: "ONLINE_APP_TRACKING" }],
    paramsData: [
      { FieldName: "LandName", FieldType: "String" },
      { FieldName: "AppNumber", FieldType: "String" },
    ],
    data: {
      LandName: this.props.propertyNum,
      AppNumber: this.props.ApplicationNumber,
    },
    errors: {},
  };

  constructor(props) {
    super(props);
    this.appTrack = this.appTrack.bind(this);
  }

  schema = {
    LandName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Property number is required";
              break;
            case "any.empty":
              err.message = "Please enter Property number";
              break;
            case "string.regex.base":
              err.message = "Please enter Property number";
              break;
            default:
              break;
          }
        });
        return errors;
      })
      .label("Property number"),
    AppNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Application number is required";
              break;
            case "any.empty":
              err.message = "Please enter Application number";
              break;
            case "string.regex.base":
              err.message = "Please enter Application number";
              break;
            default:
              break;
          }
        });
        return errors;
      })
      .label("Application number"),
  };

  appTrack = () => {
    var landName = document.getElementById("LandName").value;
    var appNumber = document.getElementById("AppNumber").value;

    console.log("landName: " + landName + ", appNumber: " + appNumber);

    this.setState({
      data: { LandName: landName, AppNumber: appNumber },
    });
  };

  doSubmit = async () => {
    try {
      const { data, ModuleName, paramsData, additionalSearch } = this.state;

      const appTrackResponse = await search(
        data,
        ModuleName,
        paramsData,
        additionalSearch
      );

      const searchResult = appTrackResponse.data["searchResult"];
      const additionSearch = appTrackResponse.data["additionSearch"];
      const searchStatus = appTrackResponse.data["searchStatus"];
      const message = appTrackResponse.data["message"];

      console.log(
        "searchResult: " +
          searchResult +
          ",additionSearch: " +
          additionSearch +
          ",searchStatus: " +
          searchStatus +
          ",message: " +
          message
      );
      if (searchStatus === "OK") {
        var additionSerchJSON = JSON.parse(additionSearch);

        if (additionSerchJSON["Table1"].length > 0) {
          this.props.updateAppTrackResults(searchResult, additionSearch);
          this.props.updateShowCase(this.props.nextStage);
        } else {
          let messageDN = (
            <span>
              Your search did not match our records. Please review the details
              or
            </span>
          );
          this.props.updateErrorMessage(
            "We are sorry",
            messageDN,
            "/home/contact",
            " contact us. ",
            ""
          );
          this.props.updateShowCase(this.props.errorStage);
        }
      }
    } catch (e) {
      console.log("doSubmit error e: " + e);
    }
  };

  render() {
    const { errorMessage } = this.state;
    return (
      <React.Fragment>
        <div className="AppTrackFormDesc">
            Please copy the property number and application number from the
            Acknowledgment Letter.Then click "Continue" to proceed.
        </div>
        <div className="display-flex">
          <div id="AppTrackSearchFormDiv">
            <form onSubmit={this.handleSubmit} className="formAppTrack">
              <div id="ErrorMessageDiv" className="ErrorMessageDiv">
                <span className="ErrorMessageExclamationMark">!</span>
                {errorMessage}
              </div>
              <div className="row">
                {this.renderInput(
                  "LandName",
                  "",
                  "colTextbox",
                  "text",
                  "Property number",
                  "",
                  "",
                  ""
                )}
              </div>

              <div className="row">
                {this.renderInput(
                  "AppNumber",
                  "",
                  "colTextbox",
                  "text",
                  "Application Number",
                  "",
                  "",
                  ""
                )}
              </div>

              <div id="AppTrackPaymentButttonsLocateDNDiv">
                <button className="btnLocateDN" onClick={this.appTrack}>
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AppTrackLocateApp;
