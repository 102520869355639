import React, { Component } from "react";
// import Form from "./form";
import {isBrowser,isMobile} from 'react-device-detect';
import ForgotPasswordEmailForm from "./forms/forgotPasswordEmailForm";
import ForgotEmailSendConfirm from "./forms/forgotEmailSendConfirm";

class ForgotPassword extends Component {
  state = {
    showForgotPwdEmail: true,
    showForgotEmailSendConfirm: false,
    fname: "",
    lname: "",
    uid: "",
    uemail: "",
    umobile: "",
    uNationalId: "",
  };

  constructor(props) {
    super(props);
    this.closeEmailForm = this.closeEmailForm.bind(this);
    this.showConformationEmail = this.showConformationEmail.bind(this);
    this.closeEmailComformation = this.closeEmailComformation.bind(this);

    //this.closeVerificationForm = this.closeVerificationForm.bind(this);
    //this.setVerificationCode = this.setVerificationCode.bind(this);
    //this.getPopData = this.getPopData.bind(this);
    //this.verifyUser = this.verifyUser.bind(this);
    //this.togglePop = this.togglePop.bind(this);
    //this.resendCode = this.resendCode.bind(this);
  }

  closeEmailForm = () => {
    this.setState({ showForgotPwdEmail: !this.state.showForgotPwdEmail });
    window.location.href = window.location.origin + "/home/login/2";
  };

  closeEmailComformation = () => {
    this.setState({
      showForgotEmailSendConfirm: !this.state.showForgotEmailSendConfirm,
    });
    window.location.href = window.location.origin + "/";
  };

  showConformationEmail = (
    username,
    fname,
    lname,
    uid,
    uemail,
    umobile,
    uNationalId
  ) => {
    this.setState({
      showForgotPwdEmail: !this.state.showForgotPwdEmail,
      showForgotEmailSendConfirm: !this.state.showForgotEmailSendConfirm,
      username: username,
      fname: fname,
      lname: lname,
      uid: uid,
      uemail: uemail,
      umobile: umobile,
      uNationalId: uNationalId,
    });
  };

  render() {
    const {
      showForgotPwdEmail,
      showForgotEmailSendConfirm,
      fname,
      lname,
      uid,
      uemail,
      umobile,
      uNationalId,
    } = this.state;

    return (
      <React.Fragment>
       { isBrowser ? (
        <div id="ForgotPasswordContainer">
          <div className="popup">
            <div className="popup_inner">
              {/* <div className="LogInPopup">
            <div className="LogInPopupInner"> */}
              {showForgotPwdEmail && (
                <ForgotPasswordEmailForm
                  closeEmailForm={this.closeEmailForm}
                  showConformationEmail={this.showConformationEmail}
                />
              )}
              {showForgotEmailSendConfirm && (
                <ForgotEmailSendConfirm
                  closeEmailComformation={this.closeEmailComformation}
                  fname={fname}
                  lname={lname}
                  uid={uid}
                  uemail={uemail}
                  umobile={umobile}
                  uNationalId={uNationalId}
                />
              )}
            </div>
          </div>
        </div>
       ) : 
       isMobile ? (<div>
          {showForgotPwdEmail && (
                <ForgotPasswordEmailForm
                  closeEmailForm={this.closeEmailForm}
                  showConformationEmail={this.showConformationEmail}
                />
              )}
              {showForgotEmailSendConfirm && (
                <ForgotEmailSendConfirm
                  closeEmailComformation={this.closeEmailComformation}
                  fname={fname}
                  lname={lname}
                  uid={uid}
                  uemail={uemail}
                  umobile={umobile}
                  uNationalId={uNationalId}
                />
              )}
       </div>):(<div>OTHER</div>)
       }

      </React.Fragment>
    );
  }
}

export default ForgotPassword;
