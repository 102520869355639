import React from "react";
import Joi from "joi-browser";
import Form from "./form";
import { sendDNPaymentRequest } from "../../services/paymentService";
import { showProgressBar,hideProgressBar } from "../../services/uiService";
import ShortValueField from "../fields/shortValueField";
import {BrowserView, MobileView} from 'react-device-detect';
import "../../styles/payments.css";

import PhoneInput from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";

class PaymentDNPayForm extends Form {
  moduleName = "LAND";
  state = {
    data: {
      PropertyNumber: "LUS/3066",
      DemandNoticeNumber: this.props.demandNoticeNumber,
      IsZilmisUser: this.props.OwnerId
        ? this.props.OwnerId > 0
          ? "true"
          : "false"
        : "false",
      WebUserId: "0",
      OwnerId: "0",
      PayerPhoneNumber: "",
      PaymentCurrency: "EUR",
      ReceiptId: "0",
      PinCode: "",
    },

    ModuleName: this.moduleName,
    deliveryType: "Digital print",
    paymentMethod: "Credit card",
    paramsData: [{ CreditCardHolderName: "LandName", FieldType: "String" }],
    errors: {},
    backToPrevStage: this.props.backToPrevStage,
    prevStage: this.props.prevStage,
  };

  schema = {
    PropertyNumber: Joi.string(),
    DemandNoticeNumber: Joi.string(),
    IsZilmisUser: Joi.string(),
    WebUserId: Joi.string(),
    OwnerId: Joi.string(),
    // PayerPhoneNumber: Joi.string()
    //   .regex(RegExp(/^(?=.*?[0-9])[0-9()+-\s]+$/))
    //   .error((errors) => {
    //     errors.forEach((err) => {
    //       switch (err.type) {
    //         case "any.required":
    //           err.message =
    //             "Please enter mobile number";
    //           break;
    //         case "any.empty":
    //           err.message =
    //             "Please enter mobile number";
    //           break;
    //         case "string.regex.base":
    //           err.message =
    //             "Please enter mobile number";
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //     return errors;
    //   }),
    // PinCode: Joi.string()
    //   .required()
    //   .error((errors) => {
    //     errors.forEach((err) => {
    //       switch (err.type) {
    //         case "any.required":
    //           err.message = "Pin code is required";
    //           break;
    //         case "any.empty":
    //           err.message = "Please enter Pin code";
    //           break;
    //         case "string.regex.base":
    //           err.message = "Please enter Pin code";
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //     return errors;
    //   })
    //   .label("Pin code"),
      CardNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Card number is required";
              break;
            case "any.empty":
              err.message = "Please enter Card number";
              break;
            case "string.regex.base":
              err.message = "Please enter Card number";
              break;
            default:
              break;
          }
        });
        return errors;
      })
      .label("Card number"),
    CardType: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.required":
            err.message = "Card type is required";
            break;
          case "any.empty":
            err.message = "Please enter Card type";
            break;
          case "string.regex.base":
            err.message = "Please enter Card type";
            break;
          default:
            break;
        }
      });
      return errors;
    })
    .label("Card type"),
    CardExpiryDate: Joi.date()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.required":
            err.message = "Card expiry date is required";
            break;
          case "any.empty":
            err.message = "Please enter Card expiry date";
            break;
          case "string.regex.base":
            err.message = "Please enter Card expiry date";
            break;
          default:
            break;
        }
      });
      return errors;
    })
    .label("Card expiry date"),
    PaymentCurrency: Joi.string(),
    ReceiptId: Joi.string(),
  };

  backStage = () => {
    const { backToPrevStage, prevStage } = this.state;
    backToPrevStage(prevStage);
  };

  confirmData = () => {
    this.state.DemandNoticeNumber = this.props.demandNoticeNumber;
    this.state.IsZilmisUser = this.props.OwnerId
      ? this.props.OwnerId > 0
        ? "true"
        : "false"
      : "false";
    this.state.OwnerId = this.props.OwnerId ? this.props.OwnerId : 0;
    this.state.WebUserId = this.props.WebUserId ? this.props.WebUserId : 0;

    //this.validateFields();
  };

  componentDidMount() {
    let { data } = this.state;
    data.PayerPhoneNumber = "+";
  }

  /*validateFields= () => {
    var elements = document.getElementsByClassName('InputWrapperDiv');
    for(var i=0;i<elements.length;i++)
    {
      console.log('input: ' + elements[i].children[0].value.length);
      if(elements[i].children[0].value.length === 0)
      {
        elements[i].children[1].innerHTML = 'Field Empty';
        elements[i].children[1].style.position = 'relative';
      }
      if(elements[i].className.indexOf('tooltip') < 0)
      {
        elements[i].className = elements[i].className + ' tooltip';
      }
    }
  };*/

  doSubmit = async () => {
    try {
      this.confirmData();
      this.state.DemandNoticeNumber = this.props.demandNoticeNumber;

      console.log(
        "doSubmit -> soSubmit: this.state.OwnerId: " +
          this.state.OwnerId +
          ",this.state.WebUserId: " +
          this.state.WebUserId
      );

      const dataObj = { ...this.state.data };
      dataObj["IsZilmisUser"] = this.props.OwnerId
        ? this.props.OwnerId > 0
          ? "true"
          : "false"
        : "false";
      this.setState({ data: dataObj });

      const { data } = this.state;

      showProgressBar();

      let response = await sendDNPaymentRequest(data, this.props);
      console.log(response);

      if (response != null) {
        if (response.status === 200) {
          if (response.data["status"] === "ERROR") {
            var messageDN = response.data["description"];
            var messageTitle = '';
            var messageTitleLink = '';

            console.log('Error at update Database after payment: ' + messageDN.split(';')[1]);

            if(messageDN.split(';')[0] === 'Could not update payment in the database')
            {
              messageDN = <span>Due to a communication error, the operation was not completed successfully.<br/>To complete the payment, please </span>;
              messageTitle = <span>contact us</span>;
              messageTitleLink = '/home/contact';
              this.props.updateErrorMessage("We are sorry", messageDN,messageTitleLink,messageTitle);
            }
            else{
              this.props.updateErrorMessage("ERROR", messageDN);
            }
            
            this.props.updateShowCase(this.props.errorStage);
          } else {
            this.props.updateStateParams(
              response.data["receiptId"],
              this.props.feeName,
              data.BillingDetails
            );

            this.props.updateShowCase(this.props.nextStage);
          }
        } else {
          this.props.updateErrorMessage(
            "ERROR",
            "General Error StatusCode: " + response.status
          );
          this.props.updateShowCase(this.props.errorStage);
        }
      } else {
        this.props.updateErrorMessage("ERROR", "General network error");
        this.props.updateShowCase(this.props.errorStage);
      }
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log(errors);
      }
    }
    hideProgressBar();
  };

  changeMobileNumber = (value) => {
    // alert(value);
    //console.log(e.target.value);
    let { data } = this.state;
    if (value !== undefined) {
      data.PayerPhoneNumber = value;
    } else {
      data.PayerPhoneNumber = "+";
    }

    this.setState({ data: data });
    //alert("mobiLe=" + this.state.data.mobile + "=");
  };

  onClickPhoneInput = (value) =>{
    let mobileInput = document.getElementById('mobileInput');
    if(mobileInput !== undefined)
    {
      mobileInput.value = '+';
    }
  }

  render() {
    const {data,errorMessage } = this.state;
    let propertyName = this.props.propertyName;
    let demandNotice = this.props.demandNoticeNumber;
    let feeNameShort = "123";
    let propertyNameShort = "";
    let demandNoticeShort = "";
    let penaltyOnArrears = this.props.penaltyOnArrears;
    let feeValueRender = this.props.feeValue;
    let feeTotal = this.props.feeTotal;
    let feeValueRenderString = feeValueRender
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let paymentMethod = "Credit card";
    let feeName = this.props.feeName;
    let feeNameRender = "";
    let penaltyOnArrearsTitle = "Penalty on Arrears (K)";
    let penaltyOnArrearsTitleShort = "";

    if (penaltyOnArrearsTitle.length > 7) {
      penaltyOnArrearsTitleShort =
        penaltyOnArrearsTitle.substring(0, 7) + " ...";
    } else {
      penaltyOnArrearsTitleShort = penaltyOnArrearsTitle;
    }

    if (feeName.length > 7) {
      feeNameShort = feeName.substring(0, 5) + " ...";
    } else {
      feeNameShort = feeName;
    }

    if (this.props.propertyName.length > 7) {
      propertyNameShort = propertyName.substring(0, 5) + " ...";
    } else {
      propertyNameShort = propertyName;
    }

    if (this.props.demandNoticeNumber.length > 7) {
      demandNoticeShort =
        this.props.demandNoticeNumber.substring(0, 5) + " ...";
    } else {
      demandNoticeShort = this.props.demandNoticeNumber;
    }

    return (
      <React.Fragment>
        <MobileView>
          <div className="h6 PaymentTitle">
            Please enter your mobile number and <br/>Pin code.Then click "Pay now" to proceed.
          </div>
        </MobileView>
        <BrowserView>
          <div className="text-black">
            <div className="h6 PaymentTitle">
              Please enter your mobile number and Pin code.<br/>Then click "Pay now" to proceed.
            </div>
          </div>
        </BrowserView>
        
        
        <MobileView>
          <div id="DNSearchLandResultsPay">
            <div className="ResultRow">
                <div className="ColumnTitleDN">Property No.</div>
                <div className="SearchResultItemDN">
                {propertyName.length > 5 ? (
                  <ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={propertyName}
                  />
                ) : (
                  <div>{propertyName}</div>
                )}
                </div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Demand Notice No.</div>
                <div className="SearchResultItemDN">
                  {demandNotice.length > 5 ?
                  (<ShortValueField
                    shortValue={demandNoticeShort}
                    fullValue={demandNotice}
                  />) : (<div>{demandNotice}</div>)
                  }
                </div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Fee name</div>
                <div className="SearchResultItemDN">
                  <ShortValueField
                    shortValue={feeNameShort}
                    fullValue={feeName}
                  />
                </div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Payment</div>
                <div className="SearchResultItemDN">{paymentMethod}</div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Cost (K)</div>
                <div className="SearchResultItemDN">
                  {feeValueRender}
                </div>
              </div>
              {
                penaltyOnArrears > 0 ?
              (
                <div>
                  <div className="ResultRow">
                    <div className="ColumnTitleDN">
                        <ShortValueField
                      shortValue={penaltyOnArrearsTitleShort}
                      fullValue={penaltyOnArrearsTitle}
                      />
                    </div>
                  <div className="SearchResultItemDN">{penaltyOnArrears}</div>
                  </div>
                  <div className="ResultRow">
                    <div className="ColumnTitleDN">Total (K)</div>
                    <div className="SearchResultItemDN">{feeTotal}</div>
                  </div>
                </div>
              )
                :
                (<div></div>)
              }
          </div>
        </MobileView>
        <BrowserView>
          <div id="DNSearchLandResultsPay">
            <div id="DNSearchResultTitles">
              <div className="ColumnTitle1">Property No.</div>
              <div className="ColumnTitle1">Demand Notice No.</div>
              <div className="ColumnTitle1">Fee name</div>
            </div>
            <div id="SearchResultData1">
              <div className="SearchResultItem1">
                {this.props.propertyName.length > 11 ? (
                  <ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={this.props.propertyName}
                  />
                ) : (
                  <div>{this.props.propertyName}</div>
                )}
              </div>
              <div className="SearchResultItem1">
                {this.props.demandNoticeNumber.length > 11 ? (
                  <ShortValueField
                    shortValue={demandNoticeShort}
                    fullValue={this.props.demandNoticeNumber}
                  />
                ) : (
                  <div>{this.props.demandNoticeNumber}</div>
                )}
              </div>
              <div id="FeeNameDiv" className="SearchResultItem2">
                <ShortValueField
                  shortValue={feeNameShort}
                  fullValue={feeNameRender}
                />
              </div>
            </div>

            <div id="DNSearchResultTitles">
              <div className="ColumnTitle">Payment</div>
              <div className="ColumnTitle">Cost (K)</div>
              {penaltyOnArrears > 0 ? (
                <div className="ColumnTitle">
                  <ShortValueField
                    shortValue={penaltyOnArrearsTitleShort}
                    fullValue={penaltyOnArrearsTitle}
                  />
                </div>
              ) : (
                <div></div>
              )}
              {penaltyOnArrears > 0 ? (
                <div className="ColumnTitle">Total (K)</div>
              ) : (
                <div></div>
              )}
            </div>
            <div id="DNSearchResultData">
              <div className="SearchResultItem">{paymentMethod}</div>
              <div className="SearchResultItem">{feeValueRenderString}</div>
              {penaltyOnArrears > 0 ? (
                <div className="SearchResultItem">{penaltyOnArrears}</div>
              ) : (
                <div></div>
              )}
              {penaltyOnArrears > 0 ? (
                <div className="SearchResultItem">
                  {feeTotal}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>      
        </BrowserView>
        <div id="PaymentsPaymentDataForm">
          <form onSubmit={this.handleSubmit} className="DNPaymentForm">
            <div id="ErrorMessageDiv" className="ErrorMessageDiv">
              <span className="ErrorMessageExclamationMark">!&nbsp;</span>
              {errorMessage}
            </div>
            <div class="PhoneAndPinCode">
              <div className="PhoneNumberWrapper">
                <div class="InputWrapperDiv" id="mobileInputDiv">
                  <PhoneInput
                      className="col-15"
                      placeholder="Mobile"
                      international
                      // defaultCountry="ZM"
                      id="mobileInput"
                      value={data.PayerPhoneNumber}
                      onChange={this.changeMobileNumber}
                      onClick={this.onClickPhoneInput}
                  />
                </div>
              </div>
              <div className="PinCodeNumberWrapper">
                {
                  this.renderPasswordNumericInput(
                  "PinCode",
                  "",
                  "colTextbox",
                  "password",
                  "Pin code",
                  "",
                  "",
                  "",
                  "",
                  "[0-9]*",
                  "numeric"
                )}

              </div>
            </div>
            <div id="PaymentButttonsDiv">
              <button
                className="btnCancel"
                type="button"
                onClick={this.backStage}
              >
                Back
              </button>
              <button className="btnConfirm" type="submit" autoFocus>
                Pay now
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
export default PaymentDNPayForm;
