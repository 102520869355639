import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
class ServicesHelp extends Component {
  state = {
    showHelp: true,
  };

  constructor(props) {
    super(props);
    this.closeHelp = this.closeHelp.bind(this);
  }

  closeHelp = () => {
    let type = this.props.match.params.type;

    if (type === 1 || type === undefined) {
      window.close();
      window.location.href = window.location.origin + "/home/register";
    }
    if (type === 2) {
      window.location.href = window.location.origin + "/home/myaccount";
      window.close();
    }
  };

  //   succsessfulySubmited = () => {
  //     this.setState({ showRegister: !this.state.showRegister });
  //     window.location.href = window.location.origin + "/";
  //     // window.location = "/home/";
  //   };
  render() {
    return (
      <React.Fragment>
        <div className="HelpPopup">
          <div className="HelpPopupInner">
            {
              isBrowser ? (
                <div className="smallCloseBtnDivHelp" onClick={() => {this.closeHelp();}}>
                  <button
                    className="closeBtn"
                    onClick={() => {
                      this.closeHelp();
                    }}
                  ></button>
                </div>
              ) : ("")
            }
            
            <div className="LogInTitleJoin">Online Services Registration</div>
            <div className="HelpParagraphWrapper">
              <div className="HelpParagraph">
                Thank you for choosing the Online Services Portal of the Ministry
                of Land and Natural Resources (MLNR).
              </div>
              <div className="HelpParagraph">
                We recommend all our clients to register and create an account.
                When you register on the portal, we will try to find your records
                in the Ministry on Lands and Natural Resources system, this way,
                we can offer you a direct access to your must relevant
                information.
              </div>
              <div className="HelpParagraph">
                Registration it is not mandatory, you can benefit from the online
                services as a visitor as well.
              </div>
              <div className="HelpParagraph">
                As a portal registered user, online services and personal zone are
                available:
              </div>
              <div className="HelpList">
                <ul>
                  <li>Lands Register Printouts</li>
                  <li>Payments</li>
                  <li>Application File Tracking</li>
                  <li>
                    Personal Zone: Lands Register Printouts history and Online
                    Payments history
                  </li>
                </ul>
              </div>
              <div className="HelpParagraph">
                As a property owner or a stakeholder registered in the portal,
                online services and personal zone are available:
              </div>
              <div className="HelpList">
                <ul>
                  <li>Lands Register Printouts</li>
                  <li>Payments</li>
                  <li>Application File Tracking</li>
                  <li>
                    Personal Zone: Lands Register Printouts history, Online
                    Payments history, Properties, Statement & Balance and
                    Application file tracking
                  </li>
                </ul>
              </div>
              <div className="HelpParagraph">
                As a visitor, the online services are available:
              </div>
              <div className="HelpList">
                <ul>
                  <li>Lands Register Printouts</li>
                  <li>Payments</li>
                  <li>Application File Tracking</li>
                </ul>
              </div>
              <div className="HelpParagraph"></div>
              <div className="HelpParagraph">
                Please do not hesitate to
                <NavLink className="LoginWithPasswordLink" to="/home/contact">
                  <span> &nbsp; contact us &nbsp;</span>
                </NavLink>
                if you have any questions.
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServicesHelp;
/*<React.Fragment>
        <div
          className="smallCloseBtnDivOneTimeLogin"
          onClick={() => {
            document.getElementById("Cancel_NotSubmitBtn").click();
          }}
        >
          <button
            className="closeBtn"
            onClick={() => {
              document.getElementById("Cancel_NotSubmitBtn").click();
            }}
          ></button>
        </div>
        <div className="LogInTitleJoin">Join us!</div>
        <div id="LoginContent">
          <div className="LogInTitle3">
            Create your account. It’s free and only take a minute
          </div>
          <div className="LogInTitle4">
            To receive the full benefits of the online services, please enter
            your personal details as they are in the Ministry of Lands and
            Natural Resouces.
          </div>

        
          <div className="LoginForm d-grid">
            <form onSubmit={this.handleRegisterFormSubmit}>
              <div id="ErrorMessageDiv" className="ErrorMessageDivLogIn">
                {errorMessage !== undefined && errorMessage.length > 0 && (
                  <span>
                    <span className="ErrorMessageExclamationMark">!</span>
                    {errorMessage}
                  </span>
                )}
                {userError !== undefined &&
                  userError.Title !== undefined &&
                  userError.Title.length > 0 && <p>{userError.Title}</p>}
                {userError !== undefined &&
                  userError.Message !== undefined &&
                  userError.Message.length > 0 &&
                  (errorMessage === undefined || errorMessage.length === 0) && (
                    <span>
                      <span className="ErrorMessageExclamationMark">!</span>
                      {userError.Message}
                    </span>
                  )}

                {userError !== undefined &&
                  userError.LinkTo !== undefined &&
                  !userError.IsLinkSeparate && (
                    <span>
                      <NavLink
                        className="LoginWithPasswordLink"
                        to={userError.LinkTo}
                      >
                        <span>{userError.LinkTitle}</span>
                      </NavLink>
                    </span>
                  )}
                {userError.LinkTo && userError.IsLinkSeparate && (
                  <span>
                    <br />
                    <NavLink
                      className="LoginWithPasswordLink"
                      to={userError.LinkTo}
                    >
                      <span>{userError.LinkTitle}</span>
                    </NavLink>
                  </span>
                )}
              </div>
           
              <div className="row">
                <div className="col">
                  <label>* Full name/Company:</label>
                </div>

                {this.renderInput(
                  "fullNameCompany",
                  "",
                  "col-35",
                  "text",
                  "Full name/Company",
                  "",
                  "",
                  "true"
                )}
              </div>
              <div className="row">
                <div className="col">
                  <label>National ID/Registration no.:</label>
                </div>

                {this.renderInput(
                  "nationalIDRegNum",
                  "",
                  "col-35",
                  "text",
                  "National ID/Registration no.",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                <div className="col">
                  <label>* E-mail:</label>
                </div>
                {this.renderInput(
                  "email",
                  "",
                  "col-35",
                  "text",
                  "E-mail",
                  "",
                  "",
                  ""
                )}
              </div>

              <div className="row">
                <div className="col">
                  <label>* Mobile:</label>
                </div>

                <div className="col-35">
              
                  <PhoneInput
                    className="col-15"
                    placeholder="Mobile"
                    international
                    defaultCountry="ZM"
                    value={data.mobile}
                    onChange={this.changeMobileNumber}
                  />
              
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>* Password:</label>
                </div>
                {this.renderInput(
                  "password",
                  "",
                  "col-35",
                  "password",
                  "Password",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                <div className="col">
                  <label>* Confirm Password:</label>
                </div>

                {this.renderInput(
                  "confirmPassword",
                  "",
                  "col-35",
                  "password",
                  "Confirm password",
                  "",
                  "",
                  ""
                )}
              </div>

              <div className="row">
                <div className="col">
                  <div className="FormButtonsDiv ml-3">
                    {this.renderNotSubmitButton(
                      "Cancel",
                      "btnCancel",
                      this.props.closeRegisterForm
                    )}
                    {this.renderButton("Save", "btnConfirm")}
                   
                  </div>
                </div>
              </div>
            </form>
            <div className="LoginWithPasswordTitle RegisterTitleMargin">
              Already registered?
              <NavLink className="LoginWithPasswordLink" to="/home/login/1">
                <span> Log in </span>
              </NavLink>
            </div>
            <div className="LoginWithPasswordTitle RegisterTitleMargin">
              For more information click
              <NavLink className="LoginWithPasswordLink" to="/home/serviceshelp">
                <span> here </span>
              </NavLink>
              or
              <NavLink className="LoginWithPasswordLink" to="/home/contact">
                <span> contact us</span>
              </NavLink>
              .
            </div>
          </div>
        </div>
      </React.Fragment>*/
