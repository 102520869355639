import React, { Component } from "react";
import "../styles/contactus.css";

class Contact extends Component {
  state = {};

  componentDidMount() {
    const timer = setTimeout(() => {
      var content = document.getElementById("ContactusContentDiv");
      content.style.display = "block";

      //content = document.getElementById("WriteusContent");
      //content.style.display = "block";

      clearTimeout(timer);
    }, 600);
  }

  render() {
    console.log("Contact Component Render");

    return (
      <React.Fragment>
        <div id="ContactusWrapper">
          <div id="ContactusPage">
            <div id="ContactusControls">
              <div id="ContactUsDiv">
                <div className="ContactusTitle">Contact Us</div>
                <div id="ContactusContentDiv">
                  <div>
                    Contact us about anything related to the Ministry
                    Online services.
                  </div>
                  <div className="ContactusDetails">
                    Tel. 09027026556 , 08166931573
                  </div>
                  <div className="ContactusDetails">Whatsapp. 09043970530</div>
                  <div className="ContactusDetails">
                    E-mail:{" "}
                    <a href="mailto:info@mol.anambrastate.org">info@mol.anambrastate.org</a>
                  </div>
                  <div id="ReceptionHoursDiv">
                    <div className="ReceptionHoursTitle">Visit our Office</div>
                    Along Ezennia Chike Okagbue, 
                    <br />
                    BoulevarD, Ahacol Phase I, 
                    <br />
                    Agu-Awka GRA, Awka, Anambra State. 
                    <br />
                    P. M. B 6003
                    <br />
                  </div>
                </div>
              </div>
              {/* <div id="WriteUsDiv">
                <div className="ContactusTitle">Write Us</div>
                <div id="WriteusContent">
                  <div>
                    Ministry of Lands and Natural Resources
                    <br /> Independence Avenue, Mulungushi Building
                    <br /> P.O.Box 50694
                    <br />
                    Ridgeway
                    <br />
                    Lusaka, Zambia
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
